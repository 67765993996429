 /* eslint-disable */ 
export default { 
  
  changePartida ({ commit }, partida) {
    commit ('SET_PARTIDA_SELECT', partida)
  },

  setIndexPartida ({ commit }, index) {
    commit ('SET_INDEX', index)
  }
}