import accessControl from "./AccessControlList";
import Vue from "vue";

function AuthPermission(to, from, next) {
  if ("meta" in to && "p" in to.meta) {
    if (
      to.meta.p == "" ||
      (accessControl.checkPermission(to.meta.p) &&
        accessControl.checkStatus(to.meta.p))
    ) {
      return next();
    } else {
      if ("app" in to.meta && to.meta.app === "ligas") {
        const isUserLogged = getUserInStorage();
        if (isUserLogged) return next();
        else return next("/403");
      } else return next("/painel/restrictArea/" + to.meta.p);
    }
  } else {
    return next();
  }
}

function getUserInStorage() {
  const userId = localStorage.getItem("userId");
  const loggedIn = localStorage.getItem("loggedIn");
  const token = Vue.$cookies.get("_nekot");

  return userId && loggedIn && token;
}

export default AuthPermission;
