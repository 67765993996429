 /* eslint-disable */ 
export default { 

  async getQtdAssinantes ({ dispatch, commit }) {
    let { data } = await Vue.http.get(url + '/assinaturas/qtd')
    return data.data
  },

  async getPlans ({ dispatch, commit }, {cpf, email, cupom, influencer}) {
    let { data } = await Vue.http.post(url + '/assinaturas/plans', {cpf, email, cupom, influencer})
    return data.data
  },

  async getAllPlans ({ dispatch, commit }) {
    let { data } = await Vue.http.get(url + '/assinaturas/plans/all')
    return data.data
  },
  
  async getAllAccess ({ dispatch, commit }) {
    let { data } = await Vue.http.get(url + '/assinaturas/access/all')
    return data.data
  },

  async flagPlan ({ dispatch, commit }, params) {
    let res = await Vue.http.post(url + '/assinaturas/plans/flag', params)
     return res
  },

  async flagAccess ({ dispatch, commit }, access) {
    let res = await Vue.http.post(url + '/assinaturas/access/flag', access)
    return res
  },
  
  async savePlan ({ commit }, { form }) {
    let { data } = await Vue.http.post(url + '/assinaturas/plans/save', { plan: form })
    return data.data
 },

 async deletePlan ({ commit }, { id_plano }) {
    let { data } = await Vue.http.post(url + '/assinaturas/plans/delete', { id_plano })
    return data.data
 },
}