export default { 
  SET_NOTIFICATIONS (state, val) {
    let e = {}
    for (let el of val) {
      if (!e[el.tipo]) e[el.tipo] = []
      e[el.tipo].push(el)
    }
    state.notifications = e
  },

  SET_ADM_NOT (state, val) {
    state.notificationsAdmin = val
  },

  SET_ADM_PAGE (state, val) {
    state.page = val
  },

  SET_ADM_TOTAL (state, val) {
    state.total = val
  },

  SET_OPEN_MODAL (state, val) {
    state.openModal = val
  },

  SET_TIPOS (state, val) {
    state.tipos = val
  },

  SET_GRUPOS (state, val) {
    state.grupos = val
  },

  SET_EDIT_NOTIFICATION (state, val) {
    state.editNotification = val
  },
  SET_EDIT_MODAL (state, val) {
    state.openEditModal = val
  }
}