export default { 
  parametros: {
    bola: [],
    tipo_jogada: [],
    posicao: [],
    scout: 'Gol',
    origem: 'conquistado',
    jogos: 'todos',
  },
  view: {
    conquistado: true,
    cedido: false
  },
  dados: null,
  loading: false,
}