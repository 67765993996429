 /* eslint-disable */ 
export default { 

  async getPaymentIntegration ({ dispatch, commit }, {operation, product}) {
    let { data } = await Vue.http.post(url + '/payment/integration', {operation, product})
    return data.data
  },

  async setPaymentType({ commit }, data) {
    await commit ('SET_PAYMENT_TYPE', data)
  },
}