// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Imports
import "./config";
import "./functions";
import "./plugins/main";

import Vue from "vue";
import Index from "./Index";
import router from "./router";
import VueResource from "vue-resource";
import store from "./store/index";
import Buefy from "buefy";
import VueWysiwyg from "@mycure/vue-wysiwyg";

import "@/plugins/main.js";

Vue.config.productionTip = false;

import Input from "./components/forms/Input";
import Select from "./components/forms/Select";
import NumberInput from "./components/forms/NumberInput";
import ModalMessage from "@/components/modals/ModalMessage";
import RestrictAccess from "@/components/restrictAccess/restrictAccess";

Vue.component("RestrictAccess", RestrictAccess);
Vue.component("ModalMessage", ModalMessage);
Vue.component("Input", Input);
Vue.component("Select", Select);
Vue.component("NumberInput", NumberInput);

import BasePublicTemplate from "@/layouts/BasePublicTemplate.vue";
Vue.component("BasePublicLayout", BasePublicTemplate);

import BaseGenericTemplate from "@/layouts/BaseGeneric.vue";
Vue.component("BaseGenericTemplate", BaseGenericTemplate);

import accessControl from "./shared/AccessControlList";
Vue.prototype.$accessControl = accessControl;

import cookies from "vue-cookies";
Vue.use(cookies);
cookies.config("30d");

import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.use(Donut);

import "@/scss/main.scss";

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/vue-flicking/dist/flicking-inline.css";

Vue.use(Flicking);

// Use
Vue.use(VueResource);
Vue.use(Buefy, {
  defaultDialogCancelText: "Cancelar",
  defaultTooltipAnimated: true,
});
Vue.use(VueWysiwyg);

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("formatterName", function(value) {
  if (!value) return "";
  value = value.toString();
  if (value.length > 15) return value.substring(0, 15);
  else return value;
});

Vue.filter("decimals", function(value) {
  return value.toLocaleString("pt-PT", { minimumFractionDigits: 2 });
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("lowercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toLowerCase();
});

Vue.config.productionTip = false;

// interceptors para inserir o token no header
Vue.http.interceptors.push((request, next) => {
  if (cookies.get("_nekot")) {
    let token = cookies.get("_nekot");
    request.headers.set("Authorization", token.toString());
  }
  // request.headers.set('access-control-allow-origin', '*')
  next();
});

import "@/_mixins/validations";
import mqMixin from "@/_mixins/mq";
Vue.mixin(mqMixin);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(Index),
  http: {
    root: "/root",
  },
}).$mount("#app");

window.Vue = Vue;
