/* eslint-disable */

import Vue from "vue";

export default {
  setLigasList({ commit }, list) {
    commit('SET_LIGAS_LIST', list);
  },

  setSalasChosen({ commit }, salas) {
    commit('SET_SALAS_CHOSEN', salas);
  },

  addSalaInCart({ commit }, sala) {
    commit('ADD_SALA_IN_CART', sala)
  },

  removeSalaFromCart({ commit }, sala) {
    commit('REMOVE_SALA_FROM_CART', sala)
  },

  openCart({ commit }) {
    commit('OPEN_CART')
  },

  closeCart({ commit }) {
    commit('CLOSE_CART')
  },

  toogleCart({ commit }) {
    commit('TOGGLE_CART')
  },

  reduceCart({ commit }) {
    commit('REDUCE_CART')
  },

  expandCart({ commit }) {
    commit('EXPAND_CART')
  },

  endCart({ commit }){
    commit('CLEAR_CART')
    commit('CLOSE_CART')
    commit('EXPAND_CART')
    commit('UPDATE_STEP_CART', 1)
  },

  updateStepCart({ commit }, step){
    commit('UPDATE_STEP_CART', step)
  },
  
  async getTeamDetails({ dispatch, commit }, { timeId, rodada, apuracao, isParcial }) {
    let parciais = isParcial ? '1' : '0'
    let { data } = await Vue.http.get(
      url + "/v2/ligas/times/detalhes/" + timeId + "/" + rodada + "/" + apuracao + "?parciais=" + parciais
    );
    return data.data;
  },

  async getHistoryLigas(
    { dispatch, commit },
    { ligaId, salaId, rodada, page, filters }
  ) {
    let query = "";
    if (typeof filters !== "undefined" && filters !== null) {
      if ("field" in filters && "value" in filters) {
        query += `?${filters.field}=${filters.value}`;
      }
    }
    query += "&page=" + page;

    let { data } = await Vue.http.get(
      url +
        "/ligas/" +
        ligaId +
        "/salas/" +
        salaId +
        "/rodada/" +
        rodada +
        query
    );
    await commit("SET_TEAMS_DATA_LIGA", data.data);
  },

  async getTeamsLigas({ dispatch, commit }, { ligaId, salaId, page, filters }) {
    let query = "";
    if (typeof filters !== "undefined" && filters !== null) {
      if ("field" in filters && "value" in filters) {
        query += `?${filters.field}=${filters.value}`;
      }
    }
    query += "&page=" + page;

    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/times" + query
    );
    await commit("SET_TEAMS_DATA_LIGA", data.data);
  },

  async getParcialLigas(
    { dispatch, commit },
    { ligaId, salaId, page, filters }
  ) {
    let query = "";
    if (typeof filters !== "undefined" && filters !== null) {
      if ("field" in filters && "value" in filters) {
        query += `?${filters.field}=${filters.value}`;
      }
    }
    query += "&page=" + page;

    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/parcial" + query
    );
    await commit("SET_TEAMS_DATA_LIGA", data.data);
  },

  async getAllLigas({ commit }) {
    let { data } = await Vue.http.get(url + "/ligas");
    return data.data;
  },

  async getLigasByStatus({ commit }, { status, id_liga }) {
    let { data } = await Vue.http.post(url + "/ligas/status", {
      status,
      id_liga,
    });
    return data.data;
  },

  async getInfluencersLigas({ commit }) {
    let { data } = await Vue.http.get(url + "/v2/ligas/influenciadores");
    return data;
  },

  async getLigasInfluencer({ commit }, { influencer, status }) {
    let query = url + "/v2/ligas?i=" + influencer;
    if (status) query += "&st=" + status;

    let { data } = await Vue.http.get(query);
    return data.data;
  },

  async getTimesInscritosInfluencer({ commit }, influencerid) {
    let { data } = await Vue.http.get(
      url + "/v2/ligas/times-inscritos?i=" + influencerid
    );
    return data.data;
  },

  async getLigaByRoom({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId
    );
    return data.data;
  },

  async getSubSalaByRoom({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/v2/ligas/" + ligaId + "/salas/" + salaId + "/infos"
    );
    return data.data;
  },

  async getLigaLevel2({ commit }, { id_liga }) {
    let { data } = await Vue.http.get(url + "/ligas/" + id_liga + "/level2");
    return data.data;
  },

  async getMyLigas({ commit }, {userId, params}) {
    let { data } = await Vue.http.post(url + `/v2/ligas/my/${userId}`, {params});
    return data.data;
  },

  async addNewLeague({ commit }, { userId, form }) {
    let { data } = await Vue.http.post(url + "/usuarios/" + userId + "/ligas", {
      liga: form,
    });
    return data.data;
  },

  async setRelSalaCategorias({ commit }, { userId, ligaId, gameId, salaId, categorias }) {
    let urlRequest = ''
    if (ligaId){
      urlRequest = url + "/usuarios/" + userId + "/ligas/" + ligaId + "/salas/" + salaId + "/categorias"
    } else if (gameId){
      urlRequest = url + "/usuarios/" + userId + "/games/" + gameId + "/salas/" + salaId + "/categorias"
    }
    let { data } = await Vue.http.post(urlRequest, {
      categorias,
    });
    return data.data;
  },

  async deleteLeague({ commit }, { userId, id_liga }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/ligas/delete",
      { id_liga: id_liga }
    );
    return data.data;
  },

  async flagLeague({ commit }, { userId, id_liga }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/ligas/flag",
      { id_liga: id_liga }
    );
    return data.data;
  },

  async sortLeague({ commit }, { userId, ligas }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/ligas/sort",
      { ligas }
    );
    return data.data;
  },

  async destaqueLeague({ commit }, { userId, id_liga }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/ligas/destaque",
      { id_liga: id_liga }
    );
    return data.data;
  },

  async destaqueLeagueInfluencer({ commit }, { userId, id_liga }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/ligas/destaque-influencer",
      { id_liga: id_liga }
    );
    return data.data;
  },

  async getSubSalas({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/info"
    );
    return data.data;
  },

  async delSubSalas({ commit }, { subSalas }) {
    let { data } = await Vue.http.post(url + "/ligas/salas/delsubsalas", {
      subSalas,
    });
    return data.data;
  },

  async getApuracaoSubSalas({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/apuracao"
    );
    return data.data;
  },

  async getTypesLeague({ commit }) {
    let { data } = await Vue.http.get(url + "/ligas/tipos");
    return data.data;
  },

  async getTypesApuracao({ commit }) {
    let { data } = await Vue.http.get(url + "/ligas/tiposApuracao");
    return data.data;
  },

  async getFiltrosApuracao({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/filtros"
    );
    return data.data;
  },

  async setNextRoundTest({ commit }) {
    let { data } = await Vue.http.post(url + "/ligas/next-round-league");
    return data.data;
  },

  async getExportSala({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/ligas/" + ligaId + "/salas/" + salaId + "/export"
    );
    return data.data;
  },

  async getArbitrosClubes({ commit }) {
    let { data } = await Vue.http.get(url + "/arbitros");
    return data.data;
  },

  async getArbitroPartida({ commit }, { arbitro }) {
    let { data } = await Vue.http.post(url + "/arbitros/partida", { arbitro });
    return data.data;
  },

  async getArbitroHistorico({ commit }, { arbitro, clube }) {
    let { data } = await Vue.http.post(url + "/arbitros/historico", {
      arbitro,
      clube,
    });
    return data.data;
  },

  async getTrades({ commit }, { userId, walletId }) {
    let { data } = await Vue.http.get(
      `${url}/usuarios/${userId}/wallet/${walletId}/trades/get`
    );
    return data.data;
  },
  async getTradesByType({ commit }, { userId, walletId, typeId }) {
    let { data } = await Vue.http.get(
      `${url}/usuarios/${userId}/wallet/${walletId}/trades/getByType/${typeId}`
    );
    return data.data;
  },
  async getFiltros() {
    let { data } = await Vue.http.get(
      url + "/ligas/filtros"
    );
    return data.data;
  },

  setPublic({ commit }, bool) {
    commit("SET_PUBLIC", bool);
  },
  setFiltros({ commit }, filtros) {
    commit("SET_FILTROS_LIGAS", filtros);
  },
  setParams({ commit }, params) {
    commit("SET_PARAMS_LIGAS", params);
  },
  setKeyParams({ commit }, obj) {
    commit("SET_PARAMS_KEY_LIGAS", obj);
  },
  setKeysEnrolled({ commit }, params) {
    commit("SET_ENROLL_KEY", params);
  },
  setSala({ commit }, sala) {
    commit("SET_SALA", sala);
  },
  setReservas({ commit }, reservas) {
    commit("SET_RESERVAS", reservas);
  },
};
