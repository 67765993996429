export default {
  SET_LIGAS_LIST (state, list) {
    state.ligasList = list;
  },
  SET_SALAS_CHOSEN (state, salas) {
    state.salasChosen = salas;
  },
  SET_TEAMS_DATA_LIGA: (state, val) => {
    state.teamsdata = val;
  },
  SET_PUBLIC: (state, val) => {
    state.isPublic = val;
  },
  SET_FILTROS_LIGAS: (state, val) => {
    state.ligasFiltros = JSON.parse(JSON.stringify(val));
  },
  SET_PARAMS_LIGAS: (state, val) => {
    state.ligasParams = JSON.parse(JSON.stringify(val));
  },
  SET_PARAMS_KEY_LIGAS: (state, { key, val }) => {
    let newParams = JSON.parse(JSON.stringify(state.ligasParams));
    newParams[key] = val;
    state.ligasParams = JSON.parse(JSON.stringify(newParams));
  },
  SET_ENROLL_KEY: (state, { key, val }) => {
    state.enroll[key] = val;
  },
  SET_SALA: (state, val) => {
    state.sala = val;
  },
  SET_RESERVAS: (state, val) => {
    state.reservedSalas = val;
  },
  ADD_SALA_IN_CART: (state, val) => {
    state.cart.push(val);
  },
  REMOVE_SALA_FROM_CART: (state, val) => {
    state.cart = state.cart.filter(sala => sala.id !== val.id);
  },
  CLEAR_CART: (state) => {
    state.cart = []
  },
  OPEN_CART: (state) => {
    state.openedCart = true
  },
  CLOSE_CART: (state) => {
    state.openedCart = false
  },
  TOGGLE_CART: (state) => {
    state.openedCart = !state.openedCart
  },
  REDUCE_CART: (state) => {
    state.reducedCart = true
  },
  EXPAND_CART: (state) => {
    state.reducedCart = false
  },
  UPDATE_STEP_CART: (state, val) => {
    state.cartStep = val;
  },
};
