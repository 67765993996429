export default { 
  
  setFinParams({ commit }, params) {
    if ('bola' in params && 'tipo_jogada' in params && 'posicao' in params && 'scout' in params && 'origem' in params && 'jogos' in params) {
      commit ('SET_PARAMS', params)
    }
  },

  finTroggleView ({ commit }, view) {
    commit ('TROGGLE_VIEW', view)
  },

  finTroggleViewFix ({ commit }, val) {
    commit ('TROGGLE_VIEW_FIX', val)
  },

  setDados ({ commit }, dados) {
    commit ('SET_DADOS', dados)
  },

  setFinLoading ({ commit }, boo) {
    commit ('SET_LOADING', boo)
  }
}