<template>
  <div class="e-modal-container">
    <div class="rg-close" @click="handlerCancel()">
      <b-icon icon="times-circle" pack="fas"></b-icon>
    </div>
    <div class="e-modal-header">
      <slot v-if="hasHeader" name="header"></slot>
      <h2 v-else>{{ title }}</h2>
    </div>

    <div class="e-modal-body">
      <slot v-if="hasBody"></slot>
      <p v-else v-html="message" class="e-modal-text"></p>
    </div>

    <div class="e-modal-footer">
      <slot v-if="hasFooter" name="footer"></slot>
      <b-button v-if="!hasFooter" :type="buttonCancelType" @click="handlerCancel()">
        {{ buttonCancelText }}
      </b-button>
      <b-button v-if="!hasFooter" :type="buttonConfirmType" @click="submit()">
        {{ buttonConfirmText }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalMessage",
  props: {
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasBody: {
      type: Boolean,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    buttonConfirmText: {
      type: String,
      default: "Finalizar",
    },
    buttonConfirmType: {
      type: String,
      default: "is-success",
    },
    buttonCancelText: {
      type: String,
      default: "Cancelar",
    },
    buttonCancelType: {
      type: String,
      default: "is-pigeon",
    },
    closeOnSubmit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handlerCancel() {
      this.$parent.close();
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
      if (this.closeOnSubmit) this.$parent.close();
    },
  },
};
</script>
