<template>
  <section id="aplication">
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {};
  },
};
</script>

<style>
body {
  height: 100%;
  width: 100%;
  background-image: url("./assets/bg_escala10.png");
  background-position: center;
  background-size: cover;
}

.card {
  border-radius: 4px !important;
}
.box {
  width: 80px;
  height: 80px;
  animation: spin 3s infinite linear;
}

.wavecontainer {
  width: 80px;
}

.boxinner {
  width: 60px;
  height: 60px;
  margin: 6px auto;
  animation: pulse 1.5s infinite linear;
  animation-timing-function: ease;
}

.line1,
.line2,
.line3,
.line4 {
  margin-left: 10px;
  position: absolute;
  width: 60px;
  height: 4px;
  background-color: #fff;
  animation: spin 1.5s infinite linear;
  animation-timing-function: ease;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
@keyframes pulinho {
  from {
    top: 0%;
  }

  to {
    top: 5%;
  }
}

.line2 {
  animation-delay: 0.1s;
}

.line3 {
  animation-delay: 0.2s;
}

.line4 {
  animation-delay: 0.3s;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  box-sizing: border-box;
  border: solid 10px rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
}

.wave {
  margin-top: 15px;
  float: left;
  width: 18px;
  height: 40px;
  box-sizing: border-box;
  border: 2px solid #fff;
  margin-left: -2px;
  animation: slide 0.6s infinite linear;
}

.wave:nth-child(2n + 1) {
  animation-delay: 0.3s;
}

.circleloader {
  border-radius: 16px;
  width: 80px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.4);
}

.bounce {
  position: absolute;
  border-radius: 16px;
  margin: 4px;
  width: 20px;
  height: 12px;
  background-color: #fff;
  animation: push 1s infinite linear;
}

.switchbox {
  position: relative;
  width: 80px;
  height: 80px;
}

.switchbox {
  animation: spin 2s infinite linear;
}

.bouncybox {
  height: 100px;
  width: 40px;
  margin-left: 20px;
}

.bouncy {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ecf0f1;
  animation: bouncy 2s infinite ease-in-out;
}

@keyframes bouncy {
  40% {
    /* height: 40px; */
  }
  50% {
    transform: translate(0, 25px);
    /* height: 40px; */
    /* border-radius: 20px; */
  }
  65% {
    /* height: 40px;  */
  }
}

@keyframes push {
  50% {
    margin-left: 56px;
  }
}

@keyframes slide {
  50% {
    margin-top: 25px;
  }
}

@keyframes pulse {
  50% {
    border-width: 30px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cross {
  50% {
    margin-left: 60px;
  }
}

/* .lg-box-align-desc-op .dropdown .dropdown-trigger {
  width: 100%;
} */
.b-tabs-edit .tabs ul {
  border-color: transparent !important;
}
.b-tabs-modal .tabs ul {
  border-color: inherit !important;
}
@media (min-width: 700px) {
  .b-tabs-edit .tabs {
    width: calc(100% - 540px);
  }
  .b-tabs-modal .tabs {
    width: 100% !important;
  }
}
.b-tabs-edit > ::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 0px;
}
.b-tabs-edit > ::-webkit-scrollbar-track {
  background-color: #878787;
  border-radius: 0px;
}
.b-tabs-edit > ::-webkit-scrollbar-thumb {
  background: rgb(67, 67, 67);
  border-radius: 0px;
}

@media (max-width: 600px) {
  .b-tabs-edit > ::-webkit-scrollbar {
    height: 2px !important;
    width: 10px;
    border-radius: 0px;
  }
  .b-tabs-edit > ::-webkit-scrollbar-thumb {
    background: rgb(28, 28, 28);
  }
}

.header-dropdown {
  z-index: 99;
  position: absolute;
  top: 44px;
  min-width: 340px;
  max-height: 450px;
  background-color: #242b38;
  border-radius: 0px 0px 4px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 3px solid #00fffd;
}

.header-dropdown > ::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 0px;
}
.header-dropdown > ::-webkit-scrollbar-track {
  background-color: #878787;
  border-radius: 0px;
}
.header-dropdown > ::-webkit-scrollbar-thumb {
  background: rgb(67, 67, 67);
  border-radius: 0px;
}

.checkbox:hover,
.checkbox span:hover,
.checkbox input:hover {
  color: #00e0fd !important;
}

.checkbox:hover .control-label,
.radio:hover .control-label {
  color: #00e0fd !important;
}

.section-2 {
  height: 100vh;
  overflow-y: auto;
}

::-webkit-resizer {
  height: 2px;
}

.select select option {
  color: rgb(230, 230, 230) !important;
}

.title,
strong {
  color: rgb(230, 230, 230) !important;
}
.subtitle {
  color: rgb(190, 190, 190) !important;
}

.title {
  margin-top: 20px;
  font-size: 1.1rem !important;
}
.subtitle {
  font-size: 0.9rem !important;
}

.label-table {
  /* font-size: 0.9rem; */
  font-size: 0.8rem !important;
  color: white !important;
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
}
.label-table i {
  font-size: 0.7rem !important;
}
.label-table p {
  margin: 0 !important;
}

@media (min-width: 1025px) {
  .title[id="title"] {
    font-size: 1.5rem !important;
  }
}

#editor {
  padding: 10px;
}

#toolbar {
  min-height: 29px;
  border-bottom: 1px solid lightgrey;
  background-color: rgb(247, 247, 247);
}

#toolbar-options {
  min-height: 29px;
  border-bottom: 1px solid lightgrey;
  overflow: hidden;
}

#editor:focus {
  outline: none;
}

.toolbar-section {
  float: left;
  border-right: 1px solid lightgrey;
}

.wysiwyg-button {
  height: 28px;
  min-width: 30px;
  background-color: transparent;
  text-align: center;
  padding: 2px;
  border: none !important;
}

.wysiwyg-button:hover {
  background-color: lightblue;
  border: none !important;
}

/* .wysiwyg-button-active {
  background-color: lightblue;
} */

.wysiwyg-button-loop {
  margin-right: 4px;
}
.hoverTableCont {
  width: 230px;
  margin-right: 30px;
  float: left;
  padding: 15px 0px 0px 15px;
}
.hoverCell {
  border: 1px solid #aaa;
  height: 16px;
  width: 16px;
  margin: 0 1px 1px 0;
  float: left;
  cursor: pointer;
}
.hoverCell:hover {
  background-color: #4b67a1 !important;
}
.hoverRow {
  display: block;
  clear: both;
}
.highlightCell {
  background-color: #d1e0ff;
}

a.dropdown-item,
.dropdown .dropdown-menu .has-link a,
button.dropdown-item {
  padding-right: 1rem !important;
}

.input:not(.custom),
.select select:not(.custom),
.textarea textarea {
  background-color: #4d4d4d !important;
  color: #ebebeb !important;
}
.label {
  color: #ebebeb !important;
}
.tabs a {
  color: #ebebeb !important;
}
.tabs a:hover {
  color: rgba(0, 240, 248, 1) !important;
  border-bottom-color: rgba(0, 240, 248, 1) !important;
}
.tabs li.is-active a {
  color: #363636 !important;
  border-bottom-color: #363636 !important;
}
.tabs.is-boxed li.is-active a {
  color: #363636 !important;
  border-bottom-color: #363636 !important;
  border-color: transparent !important;

  /* background-color: #363636 !important; */
  background: rgba(0, 240, 248, 1) !important;
}
.tabs.is-boxed a:hover {
  /* color: #363636 !important;
  border-bottom-color: #363636 !important; */
  color: rgba(0, 240, 248, 1) !important;
  border-bottom-color: rgba(0, 240, 248, 1) !important;
  background: transparent !important;
}

.tabs ul {
  margin-left: 0 !important;
}
.pagination-previous,
.pagination-next,
.pagination-link {
  color: inherit !important;
}

.table {
  background-color: #4d4d4d !important;
  color: #ebebeb !important;
  width: 100%;
}
.table td,
.table th {
  vertical-align: middle !important;
}
.table td {
  border-color: #3d3d3d !important;
}
thead th {
  color: #ebebeb !important;
}
.b-table .table-wrapper.has-sticky-header th,
.b-table .table-wrapper.has-sticky-header td {
  background-color: #4d4d4d !important;
}
.table td .checkbox-cell {
  width: 50px;
}
.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #00f0f8 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #00f0f8 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.table.is-hoverable.is-striped
  tbody
  tr:not(.is-selected):hover:nth-child(even) {
  background-color: #00f0f8 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #00f0f8 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.b-table .level:not(.top) {
  padding-bottom: 0 !important;
  padding-top: 1rem !important;
}
.table-wrapper:not(:last-child) {
  margin-bottom: 0 !important;
}

.ve-histogram {
  color: white !important;
}

/* .button[id="checkbox-button"] {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #dbdbdb !important;
} */

/* .v-select input[type=search], .v-select input[type=search]:focus {
  width: 100%;
} */

.b-checkbox.checkbox {
  outline: none;
  display: inline-flex;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.b-checkbox.checkbox + .checkbox {
  margin-left: 0.5em;
}
.b-checkbox.checkbox input[type="checkbox"] {
  position: absolute;
  left: 0;
  opacity: 0;
  outline: none;
  z-index: -1;
}
.b-checkbox.checkbox input[type="checkbox"] + .check {
  width: 1.25em;
  height: 1.25em;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid #7a7a7a;
  transition: background 0.15s ease-out;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check {
  background: #00d4d4
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #00d4d4;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-white {
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%230a0a0a'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #fff;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-black {
  background: #0a0a0a
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #0a0a0a;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-light {
  background: #f5f5f5
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23363636'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #f5f5f5;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-dark {
  background: #363636
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23f5f5f5'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #363636;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-link,
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-primary {
  background: #00d4d4
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #00d4d4;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-info {
  background: #167df0
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #167df0;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-success {
  background: #23d160
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #23d160;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-warning {
  background: #ffdd57
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='rgba(0,0,0,.7)'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #ffdd57;
}
.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-danger {
  background: #ff3860
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z' fill='%23fff'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #ff3860;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check {
  background: #00d4d4
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #00d4d4;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-white {
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%230a0a0a' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #fff;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-black {
  background: #0a0a0a
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #0a0a0a;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-light {
  background: #f5f5f5
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23363636' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #f5f5f5;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-dark {
  background: #363636
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23f5f5f5' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #363636;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-primary {
  background: #00d4d4
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #00d4d4;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-link {
  background: #00d4d4
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #00d4d4;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-info {
  background: #167df0
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #167df0;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-success {
  background: #23d160
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #23d160;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-warning {
  background: #ffdd57
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='rgba(0,0,0,.7)' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #ffdd57;
}
.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-danger {
  background: #ff3860
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23fff' d='M.15.4h.7v.2h-.7z'/%3E%3C/svg%3E")
    no-repeat 50%;
  border-color: #ff3860;
}
.b-checkbox.checkbox input[type="checkbox"]:focus + .check {
  box-shadow: 0 0 0.5em hsla(0, 0%, 48%, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check {
  box-shadow: 0 0 0.5em rgba(121, 87, 213, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-white {
  box-shadow: 0 0 0.5em hsla(0, 0%, 100%, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-black {
  box-shadow: 0 0 0.5em hsla(0, 0%, 4%, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-light {
  box-shadow: 0 0 0.5em hsla(0, 0%, 96%, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-dark {
  box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-link,
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-primary {
  box-shadow: 0 0 0.5em rgba(121, 87, 213, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-info {
  box-shadow: 0 0 0.5em rgba(22, 125, 240, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-success {
  box-shadow: 0 0 0.5em rgba(35, 209, 96, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-warning {
  box-shadow: 0 0 0.5em rgba(255, 221, 87, 0.8);
}
.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-danger {
  box-shadow: 0 0 0.5em rgba(255, 56, 96, 0.8);
}
.b-checkbox.checkbox .control-label {
  padding-left: 0.5em;
}
.b-checkbox.checkbox[disabled] {
  opacity: 0.5;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check {
  /* border-color:#7957d5 */
  border-color: #00fffd;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-white {
  border-color: #fff;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-black {
  border-color: #0a0a0a;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-light {
  border-color: #f5f5f5;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-dark {
  border-color: #363636;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-link,
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-primary {
  /* border-color:#7957d5 */
  border-color: #00fffd;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-info {
  border-color: #167df0;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-success {
  border-color: #23d160;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-warning {
  border-color: #ffdd57;
}
.b-checkbox.checkbox:hover input[type="checkbox"] + .check.is-danger {
  border-color: #ff3860;
}
.b-checkbox.checkbox.is-small {
  border-radius: 2px;
  font-size: 0.75rem;
}
.b-checkbox.checkbox.is-medium {
  font-size: 1.25rem;
}
.b-checkbox.checkbox.is-large {
  font-size: 1.5rem;
}

.b-table {
  transition: opacity 86ms ease-out;
}
@media print, screen and (min-width: 769px) {
  .b-table .table-mobile-sort {
    display: none;
  }
}
.b-table .icon {
  transition: transform 0.15s ease-out, opacity 86ms ease-out;
}
.b-table .icon.is-desc {
  transform: rotate(180deg);
}
.b-table .icon.is-expanded {
  transform: rotate(90deg);
}
.b-table .table {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  border-collapse: separate;
}
.b-table .table th {
  font-weight: 600;
}
.b-table .table th .th-wrap {
  display: flex;
  align-items: center;
}
.b-table .table th .th-wrap .icon {
  margin-left: 0.5rem;
  margin-right: 0;
  font-size: 1rem;
}
.b-table .table th .th-wrap.is-numeric {
  flex-direction: row-reverse;
  text-align: right;
}
.b-table .table th .th-wrap.is-numeric .icon {
  margin-left: 0;
  margin-right: 0.5rem;
}
.b-table .table th .th-wrap.is-centered {
  justify-content: center;
  text-align: center;
}
.b-table .table th.is-current-sort {
  border-color: #7a7a7a;
  font-weight: 700;
}
.b-table .table th.is-sortable:hover {
  border-color: #7a7a7a;
}
.b-table .table th.is-sortable,
.b-table .table th.is-sortable .th-wrap {
  cursor: pointer;
}
.b-table .table tr.detail {
  -webkit-box-shadow: inset 0px 5px 32px -15px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: inset 0px 5px 32px -15px rgba(0, 0, 0, 0.75) !important;
  box-shadow: inset 0px 5px 32px -15px rgba(0, 0, 0, 0.75) !important;
  background: #535353 !important;
}
@media screen and (max-width: 768px) {
  .b-table .table.has-mobile-cards thead {
    display: none;
  }
  .b-table .table.has-mobile-cards tfoot th {
    border: 0;
    display: inherit;
  }
  .b-table .table.has-mobile-cards tr {
    box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px hsla(0, 0%, 4%, 0.1);
    background-color: #3b3b3b !important;
    max-width: 100%;
    position: relative;
    display: block;
  }
  .b-table .table.has-mobile-cards tr td {
    border: 0;
    display: inherit;
  }
  .b-table .table.has-mobile-cards tr td:last-child {
    border-bottom: 0;
  }
  .b-table .table.has-mobile-cards tr:not(:last-child) {
    margin-bottom: 1rem;
  }
  .b-table .table.has-mobile-cards tr:not([class*="is-"]) {
    background: inherit;
  }
  .b-table .table.has-mobile-cards tr:not([class*="is-"]):hover {
    background-color: inherit;
  }
  .b-table .table.has-mobile-cards tr.detail {
    margin-top: -1rem;
  }
  .b-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td {
    display: flex;
    width: auto;
    justify-content: space-between;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
  }
  .b-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td:before {
    content: attr(data-label);
    font-weight: 600;
    padding-right: 0.5em;
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}
.columns.is-variable.is-1 {
  --columnGap: 0.25rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: 0.25rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-1-tablet {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: 0.25rem;
  }
}
.columns.is-variable.is-2 {
  --columnGap: 0.5rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: 0.5rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-2-tablet {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: 0.5rem;
  }
}
.columns.is-variable.is-3 {
  --columnGap: 0.75rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: 0.75rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-3-tablet {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: 0.75rem;
  }
}
.columns.is-variable.is-4 {
  --columnGap: 1rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}
.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}
.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}
.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}
.columns.is-variable.is-8 {
  --columnGap: 2rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}
@media print, screen and (min-width: 769px) {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

/* Estatisticas */
.align-button-title {
  width: fit-content;
  position: relative;
  top: 50%;
  left: 0%;
  z-index: 2;
  transform: translate(-20%, -120%);
}
.align-button-block {
  width: fit-content;
  position: relative;
  top: 50%;
  left: 100%;
  z-index: 2;
  transform: translate(-80%, -20%);
}
.stats-content {
  width: 100%;
  padding: 0 0 1em 0 !important;
  overflow-x: auto;
  transition: all 1s ease;
}
.block {
  /* width: 30vw; */
  padding: 1em;
  float: left;
  border-radius: 7px;
  margin-right: 35px;
  background-color: #34374b;
}
.h4-subtitle {
  width: 55%;
  position: relative;
  padding: 7px 10px;
  margin-bottom: 0 !important;
  border-radius: 7px;
  font-size: 0.7rem !important;
  text-align: center;
  color: white !important;
  font-weight: 700 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.h4-title {
  font-size: 17px !important;
  font-weight: normal !important;
  margin: 0 !important;
  text-align: center;
  color: white !important;
}
.info-title {
  float: left;
  width: 100%;
  padding: 15px 30px 5px 30px;
  height: 60px;
  border: 1px solid #868686;
  border-radius: 7px;
  margin: -50px 0 30px 0;
}
.config {
  background-color: #34374b;
  transition: 0.2s ease-in;
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  padding: 10px;
  z-index: 1;
  float: left;
  position: absolute;
  transition: 0.2s ease;
  /* -webkit-box-shadow: 0px 9px 7px -4px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 9px 7px -4px rgba(0,0,0,0.25);
  box-shadow: 0px 9px 7px -4px rgba(0,0,0,0.25); */
}
.col-content {
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  background-color: #3f435e;
}
.group-stats {
  padding: 1rem;
  position: relative;
}
@media (min-width: 342px) and (max-width: 1024px) {
  .group-stats {
    width: 90vw;
    padding-right: 0;
  }
  .block {
    margin-right: 0.5em;
  }
  .bg-header {
    border-radius: 7px !important;
  }
  .content {
    padding: 10px !important;
  }
  /* .stats-content {
    padding: 0 !important;
  } */
  .title[id="gp"] {
    font-size: 1.2rem !important;
  }
  .subtitle {
    font-size: 0.9rem !important;
  }
}
/* Final de Estatisticas */

.content {
  /* padding: 15px; */
  width: 100%;
}

@media (min-width: 342px) and (max-width: 1024px) {
  .b-tabs .tab-content {
    padding: none !important;
  }
}

#aplication {
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
}
.toasted-class {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
/* Definindo o button */
.btn {
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 20px;
}

.bt-round {
  border-radius: 50%;
}
/* Action do button */
.bt-ok,
.bt-confirm {
  color: black;
  border: 1px solid transparent !important;
  background-color: #00fffd;
}
.bt-default,
.bt-cancel {
  color: white;
  border: 1px solid transparent !important;
  background-color: transparent;
}
.bt-delete {
  color: white;
  border: 1px solid rgb(224, 65, 65);
  background-color: rgb(224, 65, 65);
}
.bt-flat {
  color: white;
  background-color: transparent !important;
}
/* Hovers */
.bt-ok:hover,
.bt-confirm:hover {
  color: #00fffd;
  background-color: black;
  transition: 0.2s ease-in-out;
}
.bt-default:hover,
.bt-cancel:hover,
.bt-flat:hover {
  transition: 0.2s ease-in-out;
  color: #00fffd;
}

/* Posicionamento */
.bt-right {
  float: right;
}
.bt-left {
  float: left;
}

/* Tamanho do Btn */
.lg {
  padding: 7px 30px !important;
}
.md {
  padding: 7px 15px !important;
}

@media (min-width: 342px) and (max-width: 1025px) {
  .btn {
    font-size: 0.9em !important;
  }
}

/* -- common -- */
.vodal,
.vodal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.vodal {
  position: fixed;
}

.vodal-mask {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}

.vodal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  padding: 15px;
  background: black;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.vodal-close {
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  z-index: 9999;
}

.vodal-close:before,
.vodal-close:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: white;
  border-radius: 100%;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.vodal-close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vodal-close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.vodal-close:hover:before,
.vodal-close:hover:after {
  background: #00fffd;
}

/* -- fade -- */
@-webkit-keyframes vodal-fade-enter {
  from {
    opacity: 0;
  }
}

@keyframes vodal-fade-enter {
  from {
    opacity: 0;
  }
}

.vodal-fade-enter-active {
  -webkit-animation: vodal-fade-enter both ease-in;
  animation: vodal-fade-enter both ease-in;
}

@-webkit-keyframes vodal-fade-leave {
  to {
    opacity: 0;
  }
}

@keyframes vodal-fade-leave {
  to {
    opacity: 0;
  }
}

.vodal-fade-leave-active {
  -webkit-animation: vodal-fade-leave both ease-out;
  animation: vodal-fade-leave both ease-out;
}

.checkbox-component > input + label > .input-box,
.radio-component > input + label > .input-box {
  position: relative;
  border: 1px solid #939393;
  border-radius: 1em;
  width: 1.6em;
  background: #fff;
  transition: background 0.2s ease-in;
  vertical-align: -15%;
}
.checkbox-component > input + label > .input-box > .input-box-tick,
.radio-component > input + label > .input-box > .input-box-circle {
  display: none;
}
.checkbox-component > input + label > .input-box:before,
.radio-component > input + label > .input-box:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid #939393;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  background: #fff;
  transition: transform 0.3s ease-out;
}
.checkbox-component > input:checked + label > .input-box,
.radio-component > input:checked + label > .input-box {
  background: #63b65d;
}
.checkbox-component > input:checked + label > .input-box:before,
.radio-component > input:checked + label > .input-box:before {
  border-color: #498d47;
  transform: translateX(0.6em);
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#app {
  height: 100%;
  width: 100%;
  display: block;
}
a:link,
a:visited {
  text-decoration: none;
}

.centralize {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
.centralize-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.mg-top-15 {
  margin-top: 15px !important;
}
.mg-top-14 {
  margin-top: 14px !important;
}
.mg-top-13 {
  margin-top: 13px !important;
}
.mg-top-12 {
  margin-top: 12px !important;
}
.mg-top-11 {
  margin-top: 11px !important;
}
.mg-top-10 {
  margin-top: 10px !important;
}

.mg-left-15 {
  margin-left: 15px !important;
}
.mg-left-14 {
  margin-left: 14px !important;
}
.mg-left-13 {
  margin-left: 13px !important;
}
.mg-left-12 {
  margin-left: 12px !important;
}
.mg-left-11 {
  margin-left: 11px !important;
}
.mg-left-10 {
  margin-left: 10px !important;
}

.mg-right-15 {
  margin-right: 15px !important;
}
.mg-right-14 {
  margin-right: 14px !important;
}
.mg-right-13 {
  margin-right: 13px !important;
}
.mg-right-12 {
  margin-right: 12px !important;
}
.mg-right-11 {
  margin-right: 11px !important;
}
.mg-right-10 {
  margin-right: 10px !important;
}

.mg-bot-15 {
  margin-bottom: 15px !important;
}
.mg-bot-14 {
  margin-bottom: 14px !important;
}
.mg-bot-13 {
  margin-bottom: 13px !important;
}
.mg-bot-12 {
  margin-bottom: 12px !important;
}
.mg-bot-11 {
  margin-bottom: 11px !important;
}
.mg-bot-10 {
  margin-bottom: 10px !important;
}

.error {
  color: red;
  font-weight: bold;
  font-size: 16px;
}
/* .error-bg {
  background-color: rgb(252, 142, 142);
} */
.scroll {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: auto;
}

@media (min-width: 320px) and (max-width: 1024px) {
  ::-webkit-scrollbar-track {
    border-radius: 20px !important;
    background-color: #b6b6b6;
  }
  ::-webkit-scrollbar {
    height: 5px !important;
    width: 7px !important;
    border-radius: 20px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px !important;
    background: rgb(73, 73, 73);
  }
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #7e7e7e;
}
::-webkit-scrollbar {
  height: 9px;
  width: 9px;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  padding-left: 0.5em;
  background: rgb(73, 73, 73);
}

.float-lt {
  float: left;
}
.float-rt {
  float: right;
}

.clear {
  clear: both;
}

.alert-row {
  float: left;
  width: calc(100% - 20px);
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 4px;
  font-size: 16px;
}

/* TOOLTIPS */
.tooltip.home-tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.tooltip.home-tooltip.sub-open {
  display: none !important;
}

.tooltip.home-tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 4px;
  padding: 10px 15px;
}

.tooltip.home-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip.home-tooltip[x-placement^="top"] {
  margin-bottom: 7.5px;
}

.tooltip.home-tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 7.5px 7.5px 0 7.5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -7.5px;
  left: calc(50% - 7.5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip.home-tooltip[x-placement^="bottom"] {
  margin-top: 7.5px;
}

.tooltip.home-tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 7.5px 7.5px 7.5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -7.5px;
  left: calc(50% - 7.5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip.home-tooltip[x-placement^="right"] {
  margin-left: 7.5px;
}

.tooltip.home-tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 7.5px 7.5px 7.5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -7.5px;
  top: calc(50% - 7.5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.home-tooltip[x-placement^="left"] {
  margin-right: 7.5px;
}

.tooltip.home-tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 7.5px 0 7.5px 7.5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -7.5px;
  top: calc(50% - 7.5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.home-tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.home-tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip.home-tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip.home-tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
/* ./TOOLTIPS */

.form-data {
  width: 100%;
  padding: 10px 0;
}
.form-input,
.form-select {
  width: -webkit-fill-available;
  margin-top: 5px;
  /* height: 40px; */
  font-size: 1em;
  color: white !important;
  border: 2px solid transparent;
  border-radius: 4px;
}
.form-input {
  padding: 0px 15px !important;
  margin-top: 0 !important;
  background-color: #4d4d4d !important;
}
.form-select .dropdown-toggle {
  color: white !important;
  border-radius: 4px !important;
  border: none;
  border: 1px solid white !important;
  background-color: #4d4d4d !important;
  /* height: auto; */
  padding: 0px !important;
  width: 100%;
}
.form-select .dropdown-toggle .clear {
  line-height: 0 !important;
}
.form-select .dropdown-menu {
  background-color: #4d4d4d !important;
}
.form-select .dropdown-menu li {
  color: white !important;
}
.form-select li > a {
  color: white !important; /* Overrides most CSS frameworks */
}
.form-select .dropdown-menu .active > a {
  color: white !important; /* Overrides most CSS frameworks */
}
.form-select .dropdown-menu > .highlight > a {
  color: black !important;
  background: #00fffd !important;
}
.multiple-select .vs__selected-options {
  /* height: -webkit-fill-available; */
  cursor: pointer;
  padding: 0.25em;
  /* overflow-y: auto; */
}
.form-select .selected-tag {
  background-color: rgb(242, 242, 242) !important;
  border: 1px solid rgb(44, 44, 44) !important;
  border-radius: 4px !important;
  padding: 2.5px 10px !important;
  color: black !important;
  position: absolute;
  margin-top: 0 !important;
  width: max-content;
}
.form-select .form-control {
  color: white !important;
}
.multiple-select .selected-tag {
  position: relative !important;
  width: auto !important;
}
.vs__actions > i {
  height: 100%;
  width: 100%;
}
.form-select .vs__actions {
  position: relative;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: #00fffd; */
  border-radius: 4px;
  padding-right: 15px !important;
  padding-bottom: 5px !important;
  color: black;
  /* background: rgba(0,255,204,1);
  background: -moz-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,255,204,1)), color-stop(100%, rgba(0,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%);
  background: linear-gradient(to right, rgba(0,255,204,1) 0%, rgba(0,255,255,1) 100%); */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 ); */
}
.form-select .open-indicator:before {
  border-color: #00fffd !important;
}
.v-select .selected-tag .close {
  background: transparent !important;
  color: black !important;
}

.form-input:focus,
.form-select:focus {
  transition: 0.2s ease-in-out;
  border: 2px solid #00fffd !important;
}

.form-input:disabled,
.form-select:disabled {
  transition: 0.2s ease-in-out;
  color: #00fffd !important;
  background-color: black !important;
  border: 2px solid #00fffd !important;
}

::-webkit-input-placeholder {
  color: #ccc;
}
.form-input::-moz-placeholder {
  color: #ccc;
}
.form-select::-moz-placeholder {
  color: #ccc;
}
​ .v-select.disabled .dropdown-toggle,
.v-select.disabled .dropdown-toggle .clear,
.v-select.disabled .dropdown-toggle input,
.v-select.disabled .open-indicator,
.v-select.disabled .selected-tag .close {
  cursor: not-allowed;
  background-color: transparent !important;
}

/* Table */
.t-container {
  width: 100%;
  color: white;
  border-collapse: collapse;
}
.t-container td {
  text-align: center !important;
  border: 1px solid #7b7b7b;
}
.t-container th {
  font-size: 16px;
}
.t-container tr:nth-of-type(2n) {
  background-color: #7b7b7b;
}

/* fields */
.inputGroup {
  margin: 15px;
  display: inline-block;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}
.inputGroup-selected {
  color: #00fffd;
}
/* .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 20px;
  vertical-align: middle;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
} */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #00fffd;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}

/* -- zoom -- */
@-webkit-keyframes vodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes vodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.vodal-zoom-enter-active {
  -webkit-animation: vodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: vodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes vodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes vodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.vodal-zoom-leave-active {
  -webkit-animation: vodal-zoom-leave both;
  animation: vodal-zoom-leave both;
}

.green {
  color: lightgreen;
}
.red {
  color: lightcoral;
}

.warning {
  color: yellow /*#f0c349*/;
}
.danger {
  color: red /*#f04949*/;
}
.success {
  color: green /*#78f049*/;
}

.no-content-msg {
  color: rgb(177, 177, 177);
  font-size: 0.9em;
}

.status-red {
  color: lightcoral !important;
}
.status-green {
  color: lightgreen !important;
}
.status-gold {
  color: #fff86a !important;
}

.fas {
  cursor: pointer !important;
}

.atl-legend {
  width: 100%;
  padding: 7px 0;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #4d4d4d;
  cursor: pointer;
  background: rgba(0, 255, 204, 1);
  background: -moz-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 255, 204, 1)),
    color-stop(100%, rgba(0, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 255, 204, 1) 0%,
    rgba(0, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffcc', endColorstr='#00ffff', GradientType=1 );
}
.atl-legend h2 {
  font-weight: bolder !important;
  font-size: 0.95rem;
}

.label {
  margin-top: 15px;
  font-size: 1em;
  color: white;
}
.field .label {
  margin-top: 0 !important;
}

#getnet-checkout-ui {
  top: 35px !important;
}

.foto-atleta {
  background-color: white;
  position: relative;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  top: 7px;
  overflow: hidden;
  float: left;
  padding: 8px;
}
.foto-atleta img,
.foto-atleta span {
  background-color: white;
  position: relative;
  max-width: fit-content;
  border-radius: 50%;
  width: 125%;
  top: 50%;
  left: 50%;
  /* padding: 3px; */
  transform: translate(-50%, -50%);
}
.foto-atleta span {
  padding: 20px;
}
.info-atleta {
  position: relative;
  top: 15px;
  left: 2px;
  /* text-align: center; */
  float: left;
}

.escudo-clube {
  background-color: white;
  position: relative;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  height: 23px;
  width: 23px;
  left: 35px;
  top: 12px;
  transform: translate(-2%, 125%);
}
.escudo-clube img {
  position: relative;
  width: -webkit-fill-available;
  left: 50%;
  padding: 3px;
  transform: translate(-50%, -254%);
}

/* .info-atleta .info-jogador,
.info-atleta .info-position {
  float: left;
} */
.info-atleta .info-jogador {
  font-weight: 400;
  font-size: 0.7rem;
}
.info-atleta .info-position {
  opacity: 0.6;
  font-size: 0.6rem;
}

@media screen and (max-width: 768px) {
  /*
  .escudo-clube {
    height: 25px !important;
    width: 25px !important;
    left: 49% !important;
    top: 15px !important;
    transform: translate(-10%, 66%) !important;
  }
  .foto-atleta {
    top: 10px !important;
    height: 40px !important;
    width: 40px !important;
  }
  .escudo-clube img {
    left: 53% !important;
    transform: translate(-50%, -168%) !important;
  }
 */
}

.button:hover,
.button.is-hovered {
  color: black !important;
}
.button.is-success:hover,
.button.is-success.is-hovered,
.button.is-danger:hover,
.button.is-danger.is-hovered,
.button.is-warning:hover,
.button.is-warning.is-hovered,
.button.is-purple:hover,
.button.is-purple.is-hovered,
.button.is-info:hover,
.button.is-info.is-hovered,
.button.is-link:hover,
.button.is-link.is-hovered {
  color: white !important;
}
</style>
