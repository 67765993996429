import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/users',
    name: 'Usuários',
    component: () => import('../../components/Users/Users'),
    meta: { p: "cms.users" },
    beforeEnter: AuthPermission
  },
]
