<template>
  <div v-if="existList" class="smd-list-links">
    <a
      v-for="(socialMedia, index) of list"
      :key="`smd-${index}`"
      :href="socialMedia.link"
      v-tooltip.bottom="{
        content: socialMedia.content,
        classes: 'home-tooltip',
      }"
      target="_blank"
      class="smd-link"
      :style="{ color }"
    >
      <b-icon :pack="socialMedia.pack" :icon="socialMedia.icon"></b-icon>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialMedias",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "#00f0f8",
    },
    backgroundColor: {
      type: String,
      default: "#1313136b",
    },
    borderColorHover: {
      type: String,
      default: "#00f0f8",
    },
  },
  computed: {
    existList() {
      return this.list.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.smd-list-links {
  display: flex;
  flex-direction: row;

  .smd-link {
    width: 35px;
    height: 35px;
    margin: 0 0.35em;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: #1313136b;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;

    &:hover {
      transition: all 0.2s ease-in-out;
      border: 1px solid #00f0f8;
    }
  }
}
</style>
