/* eslint-disable */

import Vue from "vue";

export const getUser = async ({ commit }, userId) => {
  try {
    if (typeof userId != "undefined") {

      await commit("SET_RENDER_MENU", false);
      
      let token = Vue.$cookies.get("_nekot");
      let res = await Vue.http.get(url + "/usuarios/" + userId, {
        Authorization: "", //token.toString()
      });
      let user = (await res.json()).data;
      
      await commit("SET_LOGIN_ACCESS", user.acesso);
      await commit("SET_LOGIN_EXPIRED", user.expirado);
      await commit("SET_EXIST_PAYMENT", user.pagamento);
      await commit("SET_USER_INFO", user);
      await commit("SET_USER_PERMISSION", user.permission);
      await commit(
        "SET_ACTIVE_PERMISSION",
        user.permission
          .filter(
            (p) =>
              p.page.ativo &&
              (p.pageFunction.ativo || p.pageFunction.ativo == null)
          )
          .map((p) => {
            return p.slug;
          })
      );
      await commit("SET_ADMIN", user.isAdmin);

      if (user.plano == 0) {
        await commit("SET_ONLY_LIGA", true);
      }

      await commit("SET_RENDER_MENU", true);
    }
  } catch (err) {
    //devera ser tratado de outra maneira, sem ser uma exception
    //throw new Error('GET_USER', err.message)
  }
};

export const getValidUser = async ({ dispatch, commit, state }, userValid) => {
  try {
    if (typeof userValid != "undefined") {
      let res = await Vue.http.post(url + "/usuarios/valid", {
        user: userValid,
      });
      let valid = (await res.json()).data;

      if (valid) {
        commit("SET_USER_VALID", valid);
      } else {
        throw new Error("ERROR_USER_VALID_AVAILABILITY");
      }
    }
  } catch (err) {
    console.error(err);
    throw new Error("ERROR_USER_VALID");
  }
};

export const addUser = async ({ dispatch, commit, state }, userInfo) => {
  try {
    let res = await Vue.http.post(url + "/usuarios/add", { user: userInfo });
    let auth = (await res.json()).data;

    if (auth) {
      if (auth.register) {
        localStorage.loggedIn = auth.register;
        localStorage.userId = auth.userId;
        Vue.$cookies.set("_nekot", auth.token);

        await commit("SET_LOGIN", auth.register);
        await commit("SET_USER_ID", auth.userId);
        await dispatch("getUser", auth.userId);
        await commit("SET_TOKEN", auth.token);
      }
    } else {
      throw new Error("ERROR_AUTH_REGISTER");
    }
  } catch (err) {
    console.error(err);
    throw new Error("ERROR_REGISTER");
  }
};

export const updateUser = async ({ dispatch, commit, state }, userInfo) => {
  try {
    let token = Vue.$cookies.get("_nekot");
    let res = await Vue.http.post(
      url + "/usuarios/update",
      { user: userInfo },
      {
        Authorization: token.toString(),
      }
    );
    let auth = (await res.json()).data;

    localStorage.loggedIn = false;

    if (auth) {
      if (auth.update) {
        localStorage.loggedIn = auth.update;
        localStorage.userId = auth.userId;
        Vue.$cookies.set("_nekot", auth.token);

        await commit("SET_LOGIN", auth.update);
        await commit("SET_USER_ID", auth.userId);
        await dispatch("getUser", auth.userId);
        await commit("SET_TOKEN", auth.token);
      } else if (auth.error) {
        return auth.error;
      } else {
        throw new Error("ERROR_AUTH_UPDATE");
      }
    } else {
      throw new Error("ERROR_AUTH_UPDATE");
    }
  } catch (err) {
    console.error(err);
    throw new Error("ERROR_UPDATE");
  }
};

export const updateSignature = async (
  { dispatch, commit, state },
  userInfo
) => {
  try {
    let token = Vue.$cookies.get("_nekot");
    let res = await Vue.http.post(
      url + "/usuarios/updateSignature",
      { user: userInfo },
      {
        Authorization: token.toString(),
      }
    );
    let auth = (await res.json()).data;

    if (auth) {
      if (auth.update) {
        await commit("SET_LOGIN", auth.update);
        await commit("SET_USER_ID", auth.userId);
        await dispatch("getUser", auth.userId);
      }
    } else {
      throw new Error("ERROR_AUTH_UPDATE");
    }
  } catch (err) {
    console.error(err);
    throw new Error("ERROR_UPDATE");
  }
};

export const authLogin = async ({ dispatch, commit }, auth) => {
  await commit("SET_LOGIN", auth.login);
  await commit("SET_USER_ID", auth.id);
  await dispatch("getUser", auth.id);
  await commit("SET_TOKEN", auth.token);
};

export const signOut = async ({ dispatch, commit }) => {
  commit("SET_USER_ID", 0);
  commit("SET_USER_INFO", {});
  commit("SET_TOKEN", "");

  localStorage.removeItem("loggedIn");
  localStorage.removeItem("userId");
  Vue.$cookies.remove("_nekot");

  commit("SET_LOGIN", false);
};

export const setLogin = async ({ dispatch, commit }, userId) => {
  await commit("SET_LOGIN", true);
  await commit("SET_USER_ID", userId);
  await dispatch("getUser", userId);
};

export const getAtletas = async ({ commit }) => {
  let res = await Vue.http.get(url + "/atletas");

  let atletas = (await res.json()).data;

  await commit("GET_ATLETAS", atletas);
  localStorage.atletas = JSON.stringify(atletas);
};

export const setConfigAtletas = async ({ dispatch, commit }, config) => {
  await commit("SET_CONFIGATLETAS", config);
};

export const getStatus = async ({ dispatch, commit }) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(url + "/atletas/status");

  let statusAtletas = (await res.json()).data;

  await commit("GET_STATUS_ATLETAS", statusAtletas);
};

export const getScouts = async ({ dispatch, commit }) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(url + "/atletas/scouts");

  let scouts = (await res.json()).data;

  await commit("GET_SCOUTS", scouts);
};

export const getPosicoes = async ({ dispatch, commit }) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(url + "/atletas/posicao");

  let posicoes = (await res.json()).data;

  await commit("GET_POSICOES", posicoes);
};

export const getMercado = async ({ dispatch, commit }) => {
  // Jeferson Couto em 02/10/2020 - Retirado o token da chamada das infos do mercado, pois na tela de ligas dos influenciadores n�o ter� o login
  // let token = Vue.$cookies.get('_nekot')
  // let res = await Vue.http.get(url + '/mercado', {
  //   'Authorization': token.toString()
  // })
  let res = await Vue.http.get(url + "/mercado");

  let mercado = (await res.json()).data;

  await commit("GET_MERCADO", mercado);
};

export const getWallet = async ({ dispatch, commit }, userId) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(`${url}/usuarios/${userId}/wallet`, {
    Authorization: token.toString(),
  });
  let data = await res.json();

  if (data.message == "Resource not found") {
    await dispatch("newWallet", userId);
  } else {
    let wallet = data.data;
    await commit("GET_WALLET", wallet);
  }
};

export const getWalletById = async (
  { dispatch, commit },
  { userId, walletId }
) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(`${url}/usuarios/${userId}/wallet/${walletId}`, {
    Authorization: token.toString(),
  });
  let data = await res.json();

  let wallet = data.data;
  await commit("GET_WALLET", wallet);
};

export const newWallet = async ({ dispatch, commit }, userId) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.post(
    `${url}/usuarios/${userId}/wallet/create`,
    {
      payment: {},
    },
    {
      Authorization: token.toString(),
    }
  );
  let data = await res.json();

  if (data.status != "error") {
    let params = {
      userId: userId,
      walletId: data.data.id_wallet,
    };
    await dispatch("getWalletById", params);
  } else {
    await commit("writeStatus", {
      success: false,
      message: data.message,
      processing: false,
    });
  }
};

export const doTrade = async (
  { dispatch, commit },
  { userId, id_liga, id_sala, id_time_cartola, value, id_wallet }
) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.post(
    `${url}/usuarios/${userId}/wallet/${id_wallet}/trades/add`,
    {
      trade: {
        id_liga: id_liga,
        id_sala: id_sala,
        id_time_cartola: id_time_cartola,
        value: value,
      },
    },
    {
      Authorization: token.toString(),
    }
  );

  let trade = (await res.json()).data;
  // if (trade.action) verificar se deu certo...
  await dispatch("getWallet", userId);
};

export const getLeagues = async ({ dispatch, commit }) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(`${url}/ligas`, {
    Authorization: token.toString(),
  });
  let data = await res.json();

  if (data.status != "error") {
    let leagues = data.data;
    await commit("GET_LEAGUES", leagues);
  } else {
    await commit("writeStatus", {
      success: false,
      message: data.message,
      processing: false,
    });
  }
};

export const getLeagueInfo = async ({ dispatch, commit }, userId) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(`${url}/ligas/usuario/${userId}/info`, {
    Authorization: token.toString(),
  });
  let data = await res.json();

  if (data.status != "error") {
    let info = data.data;
    await commit("GET_LEAGUES_INFO", info);
  } else {
    await commit("writeStatus", {
      success: false,
      message: data.message,
      processing: false,
    });
  }
};

export const setLeagues = async ({ dispatch, commit }, userId) => {
  await dispatch("getLeagues");
  await dispatch("getLeagueInfo", userId);
};

export const getConfig = async (
  { dispatch, commit },
  { userId, configName }
) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(
    `${url}/usuarios/${userId}/configs/${configName}`,
    {
      Authorization: token.toString(),
    }
  );
  let data = await res.json();

  if (data) {
    if (data.data) {
      if (data.data.length > 0) {
        return data.data[0];
      }
    }
  }
};

export const getClubs = async ({ dispatch, commit }, configs) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.post(
    `${url}/clubes`,
    { configs },
    {
      Authorization: token.toString(),
    }
  );
  let data = await res.json();

  if (data) {
    if (data.data) {
      return data.data;
    }
  }
};

export const getClubsDetails = async ({ dispatch, commit }, configs) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.post(
    `${url}/clubes/detalhes`,
    { configs },
    {
      Authorization: token.toString(),
    }
  );
  let data = await res.json();

  if (data) {
    if (data.data) {
      return data.data;
    }
  }
};

export const getAtletasList = async ({ dispatch, commit }, query) => {
  let token = Vue.$cookies.get("_nekot");
  let res = await Vue.http.get(`${url}/v2/atletas/list?${query}`, {
    Authorization: token.toString(),
  });
  let data = await res.json();

  if (data) {
    if (data.data) {
      return data.data;
    }
  }
};

export const troggleSidebar = async ({ commit }, active) => {
  await commit("SET_STATUS_SIDEBAR", active);
};
