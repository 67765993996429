import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/influencers',
    name: 'Influenciadores',
    component: () => import('../../components/Influencers/Influenciadores'),
    meta: { p: "cms.influencers" },
    beforeEnter: AuthPermission
  },
]
