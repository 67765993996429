export default { 
  notifications: {},
  notificationsAdmin: [],
  openModal: false,
  tipos: [],
  grupos: [],
  page: 1, 
  total: 0,
  editNotification: {},
  openEditModal: false,
}