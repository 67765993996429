import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/access',
    name: 'Acessos',
    component: () => import('../../components/Access/Access'),
    meta: { p: "cms.access" },
    beforeEnter: AuthPermission
  },
]
