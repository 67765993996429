import AuthPermission from "@/shared/Authetication";

export default [
  {
    path: "/painel",
    redirect: "/painel/resumo",
  },
  {
    path: "/painel/atletas",
    name: "Athletes",
    meta: { p: "atletas", requiresAuth: true, maintenance: maintenance },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas" */ "../pages/Athletes/Athletes.vue"
      ),
  },
  {
    path: "/painel/atletas/:aid",
    name: "AthleteDetails",
    meta: {
      p: "atletas.details",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas/:aid" */ "../pages/AthleteDetails/AthleteDetails.vue"
      ),
  },
  {
    path: "/painel/cruzamento-scouts",
    name: "ScoutsAnalysis",
    meta: {
      p: "cruzamentoScouts",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas" */ "../pages/ScoutsAnalysis/ScoutsAnalysis.vue"
      ),
  },
  {
    path: "/painel/resumo",
    name: "Overview",
    beforeEnter: AuthPermission,
    meta: {
      p: "resumo",
      requiresAuth: true,
      maintenance: maintenance,
    },
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas" */ "../pages/Overview/Overview.vue"
      ),
  },
  {
    path: "/painel/recorrencia",
    name: "Recurrence",
    beforeEnter: AuthPermission,
    meta: {
      p: "recorrencia",
      requiresAuth: true,
      maintenance: maintenance,
    },
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas" */ "../pages/Overview/Recurrence.vue"
      ),
  },
  {
    path: "/painel/gols-assistencias",
    name: "GoalsAndAssists",
    meta: {
      p: "assistenciasGols",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/atletas" */ "../pages/GoalsAndAssists/GoalsAndAssists.vue"
      ),
  },
  {
    path: "/painel/arbitros",
    name: "Referees",
    component: () =>
      import(
        /* webpackChunkName: "painel/arbitros" */ "../pages/Referees/Referees.vue"
      ),
    meta: { p: "arbitros", requiresAuth: true, maintenance: maintenance },
    beforeEnter: AuthPermission,
  },
  {
    path: "/painel/arbitros/:slug",
    name: "Referee Details",
    component: () =>
      import(
        /* webpackChunkName: "painel/arbitros" */ "../pages/Referees/RefereeDetails.vue"
      ),
    meta: { p: "arbitros", requiresAuth: true, maintenance: maintenance },
    beforeEnter: AuthPermission,
  },
  {
    path: "/painel/ranking-atletas",
    name: "RankingAthletes",
    meta: {
      p: "rankingAtletas",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/ranking-atletas" */ "../pages/RankingAthletes/RankingAthletes.vue"
      ),
  },
  {
    path: "/painel/ranking-clubes",
    name: "RankingClubs",
    meta: {
      p: "rankingClubes",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/ranking-atletas" */ "../pages/RankingClubs/RankingClubs.vue"
      ),
  },
  {
    path: "/painel/jogos",
    name: "Matches",
    meta: {
      p: "jogos",
      requiresAuth: true,
      maintenance: maintenance,
    },
    beforeEnter: AuthPermission,
    component: () =>
      import(
        /* webpackChunkName: "painel/ranking-atletas" */ "../pages/Matches/Matches.vue"
      ),
  },
];
