import state from "./state";

export default {
  getTeamsData(state) {
    return state.teamsdata;
  },
  salasChosenStore() {
    return state.salasChosen;
  },
  isPublic(state) {
    return state.isPublic;
  },
  filtros(state) {
    return state.ligasFiltros;
  },
  params(state) {
    return state.ligasParams;
  },
  getAvailableTeams(state) {
    return state.enroll.availableTeams;
  },
  getTeamsEnrolled(state) {
    return state.enroll.teamsEnrolled;
  },
  salasStore(state) {
    return state.ligasList.filter(sala => sala.id);
  },
  salasList(state) {
    return state.ligasList;
  },
  reservedSalas(state) {
    return state.reservedSalas
  },
  cart(state) {
    return state.cart
  },
  openedCart(state) {
    return state.openedCart
  },
  reducedCart(state) {
    return state.reducedCart
  },
  cartStep(state) {
    return state.cartStep
  }
};
