<template>
  <div id="main-layout-public" class="layout-center">
    <NavBarDefault></NavBarDefault>
    <div class="content-block-article">
      <slot />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBarDefault from "./components/NavbarDefault";
import Footer from "./components/Footer";

export default {
  name: "Basegeneric",
  components: {
    NavBarDefault,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.layout-center {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #1f213179;
  position: relative;
}
.content-block-article {
  position: relative;
  padding-top: 20px;
  min-height: 75vh;

  @media (max-width: 568px) {
    & {
      padding: 15px 1em;
    }
  }
}
</style>
