export default { 
  getLigasTimesUser (state) { return state.times },
  
  getGamesTimesUser (state) { return state.timesGames },

  getDesempenho (state) { return state.desempenho },

  getTeamsSalas (state) {
    let salas = {}
    state.times.map(time => {
      time.salas.map(el => {
        salas[el] = el;
        return el; 
      });

      return time;
    });
    return Object.values(salas);
  }
}