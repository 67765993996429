import Vue from "vue";
import VueMq from "vue-mq"; // media queries no vue

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 450,
    md: 1024,
    lg: Infinity,
  },
  defaultBreakpoint: "md", // customize this for SSR
});
