 /* eslint-disable */ 
export default { 
  async getAdminTypeTrade ({ commit }, {adminUserId}) {
    let { data } = await Vue.http.get(url + '/admin/' + adminUserId + '/trades/types')
    await commit('SET_ADMIN_TYPE_TRADE', data.data)
    return data.data
  },
  async getAdminWallet ({ commit }, {adminUserId, userId}) {
    let { data } = await Vue.http.get(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/wallet')
    await commit('SET_ADMIN_WALLET', data.data)
    return data.data
  },
  async getAdminTrades ({ commit }, {adminUserId, userId, walletId}) {
    let { data } = await Vue.http.get(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/wallet/' + walletId + '/trades/get')
    return data.data
  },
  async getAdminTradesByType ({ commit }, {adminUserId, userId, walletId, typeId}) {
    let { data } = await Vue.http.get(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/wallet/' + walletId + '/trades/getByType/' + typeId)
    return data.data
  },
  async addAdminMovTrade ({ commit }, {adminUserId, userId, walletId, trade}) {
    let { data } = await Vue.http.post(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/wallet/' + walletId + '/trades/addmov', { trade })
    return data
  },

  async disableAdminTeam ({ commit }, { adminUserId, userId, teamId }) {
    let { data } = await Vue.http.put(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/times/' + teamId + '/disable')
    return data
  },
  async enableAdminTeam ({ commit }, { adminUserId, userId, teamId }) {
    let { data } = await Vue.http.put(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/times/' + teamId + '/enable')
    return data
  },

  async getAdminTimesCartola ({ commit }, { adminUserId, userId, searchTeam }) {
    let { data } = await Vue.http.put(url + '/admin/' + adminUserId + '/usuarios/' + userId + '/times/search?time=' + searchTeam)
    return data
  }

}