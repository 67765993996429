export default { 
  async setNotifications({ commit }, data) {
    await commit ('SET_NOTIFICATIONS', data)
  },

  async setNotificationsAdmin({ commit }, data) {
    await commit ('SET_ADM_NOT', data)
  },

  async setNotificationsAdminPage({ commit }, data) {
    await commit ('SET_ADM_PAGE', data)
  },

  async setNotificationsAdminTotal({ commit }, data) {
    await commit ('SET_ADM_TOTAL', data)
  },

  async setNotificationActiveModal ({ commit }, active) {
    await commit ('SET_OPEN_MODAL', active)
  },

  async setNotificationTipos ({ commit }, tipos) {
    await commit ('SET_TIPOS', tipos)
  },

  async setNotificationGrupos ({ commit }, grupos) {
    await commit ('SET_GRUPOS', grupos)
  },

  async setNotificationEdit ({ commit }, data) {
    await commit ('SET_EDIT_NOTIFICATION', data)
  },

  async setNotificationOpenEdit ({ commit }, active) {
    await commit ('SET_EDIT_MODAL', active)
  }
}