export default { 
  notificacoes (state) {
    return state.notifications
  },
  notificacoesAdmin (state) {
    return state.notificationsAdmin
  },
  notificacoesAdminPage (state) {
    return state.page
  },
  notificacoesAdminTotal (state) {
    return state.total
  },
  notificationActiveModal (state) {
    return state.openModal
  },
  notificationTypes (state) {
    return state.tipos
  },
  notificationTargetsGroups (state) {
    return state.grupos
  },
  notificationEdit (state) {
    return state.editNotification
  },
  notificationActiveEditModal (state) {
    return state.openEditModal
  },
}