import Vue from "vue";
import Vuex from "vuex";

import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import Admin from "./modules/Admin/index";
import Atletas from "./modules/Atletas/index";
import Leagues from "./modules/leagues";
import Ligas from "./modules/Ligas/index";
import MiniGames from "./modules/MiniGames/index";
import Influenciadores from "./modules/Influencers/index";
import Times from "./modules/Times/index";
import User from "./modules/User/index";
import Plans from "./modules/Plans/index";
import Mercado from "./modules/Mercado/index";
import Partidas from "./modules/Partidas/index";
import Finalizacoes from "./modules/Finalizacoes/index";
import Clubes from "./modules/Clubes/index";
import Notificacoes from "./modules/Notificacoes/index";
import Payment from "./modules/Payment/index";
import Categorias from "./modules/Categorias/index";
import Championships from "./modules/Championships/index";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Admin,
    Atletas,
    Leagues,
    Ligas,
    MiniGames,
    Influenciadores,
    Times,
    User,
    Plans,
    Mercado,
    Partidas,
    Finalizacoes,
    Clubes,
    Notificacoes,
    Payment,
    Categorias,
    Championships,
  },
  state: {
    http: null,
    userInfo: {},
    userValid: {},
    userPermission: [],
    activePermission: [],
    renderMenu: false,
    userId: 0,
    loggedIn: false,
    loginAccess: false,
    loginExpired: false,
    token: "",
    statusAtletas: [],
    scouts: [],
    posicoes: [],
    mercado: {},
    atletas: [],
    configAtletas: {},
    isAdmin: false,
    onlyLiga: false,
    wallet: [],
    openSideBar: true,
    leagues: {
      data: [],
    },
    modal: {},
    writeStatus: {
      success: false,
      message: "",
      processing: false,
    },
  },
  actions,
  getters,
  mutations,
  strict: false,
});

export default store;
