import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/payout',
    name: 'Payout',
    component: () => import('../../components/Payout/Payout'),
    meta: { p: "cms.payout" },
    beforeEnter: AuthPermission
  }
]
