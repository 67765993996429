import AuthPermission from "../../../shared/Authetication";

export default [
  {
    path: "/ligas",
    name: "Ligas",
    meta: { maintenance: maintenance },
    component: () => import("../pages/Leagues"),
  },
  {
    path: "/ligas/c/:slug",
    name: "Categoria",
    meta: { maintenance: maintenance },
    component: () => import("../pages/Category"),
  },
  {
    path: "/ligas/caixa",
    name: "Caixa",
    meta: { maintenance: maintenance, p: "ligas", app: "ligas" },
    beforeEnter: AuthPermission,
    component: () => import("../pages/Caixa"),
  },
  {
    path: "/ligas/:influencer",
    name: "Ligas Influenciadores",
    meta: { maintenance: maintenance },
    component: () => import("../pages/Leagues"),
  },
  {
    path: "/ligas/:influencer/c/:slug",
    name: "Categoria Influenciadores",
    meta: { maintenance: maintenance },
    component: () => import("../pages/Category"),
  },
  {
    path: "/ligas/:influencer/caixa",
    name: "Caixa Influenciadores",
    meta: { maintenance: maintenance, p: "ligas", app: "ligas" },
    beforeEnter: AuthPermission,
    component: () => import("../pages/Caixa"),
  },
];
