 /* eslint-disable */ 
export default {
  namespaced: true,
  state: {
    details: {},
    partial: [],
    statusLeague: {
      status: false,
      rodada: 0,
      total: 0
    }
  },
  getter: {
    details: (state) => state.details
  },
  mutations: {
    GET_ONLY_LEAGUE: (state, league) => state.details = league,
    
    GET_PARCIAL: (state, times) => {
      let aux = state.partial
      
      aux.push(...times)

      // if (state.partial.length > 0) {
      //   let a  = aux.map(el => el.time_id)
      //   aux = aux.filter(e => a.indexOf(e.time_id) == -1)
      // }
      
      state.partial = times
    },
    
    GET_STATUS_LEAGUE: (state, infos) => {
      state.statusLeague = {
        status: infos.status,
        rodada: infos.rodada,
        total: infos.total
      } 
    },

    SET_TEAMS_REGISTERED_UNIQUE: (state, info) => {
      state.details.user_teams_registered = info[state.details.id_liga]
    }
  },
  actions: {
    getLeague: async ({ dispatch, commit, state }, ligaId) => {
      if (ligaId) {
        let token = Vue.$cookies.get('_nekot')
        let res = await Vue.http.get(`${url}/ligas/${ligaId}`, {
          'Authorization': token.toString()
        })
        let data = await res.json()
        
        if (data.status != 'error') {
          let [league] = data.data
          await commit('GET_ONLY_LEAGUE', league)
        }
      
        else {
          await commit('writeStatus', { success: false, message: 'Erro ao carregar a Liga', processing: false})
        }

      } else await commit('writeStatus', { success: false, message: 'Liga indefinida, escolhe uma liga valida!', processing: false})

    },
    getPartial: async ({ dispatch, commit, state }, { ligaId, page }) => {
      if (ligaId) {
        let token = Vue.$cookies.get('_nekot')
        let res = await Vue.http.get(`${url}/ligas/${ligaId}/parcial?page=${page}`, {
          'Authorization': token.toString()
        })
        let data = await res.json()
        
        if (data.status != 'error') {
          let { status, rodada, times, total } = data.data

          await commit('GET_PARCIAL', times)
          await commit('GET_STATUS_LEAGUE', { status: status, rodada: rodada, total: total })
        }
      
        else {
          await commit('writeStatus', { success: false, message: 'Erro ao carregar a Liga', processing: false})
        }

      } else await commit('writeStatus', { success: false, message: 'Liga indefinida, escolhe uma liga valida!', processing: false})
 
    },
    getTeamsRegistered: async ({ dispatch, commit }, userId) => {
      let token = Vue.$cookies.get('_nekot')
      let res = await Vue.http.get(`${url}/ligas/usuario/${userId}/info`, {
        'Authorization': token.toString()
      })
      let data = await res.json()
    
      if (data.status != 'error') {
        let info = data.data
        await commit('SET_TEAMS_REGISTERED_UNIQUE', info)
      }
    
      else {
        await commit('writeStatus', { success: false, message: data.message, processing: false})
      }
    }
  }
}