<template>
  <footer id="main-footer">
    <div class="container">
      <div class="content">
        <div class="columns">
          <div class="column is-2">
            <img
              class="ajust-brand"
              src="../../assets/logotipo_green.png"
              alt="Escala 10"
              :style="$mq == 'sm' ? 'width: 55px;' : 'width: 120px'"
            />
          </div>
          <div class="column">
            <div class="level">
              <div class="level-left">
                <b-icon icon="whatsapp" pack="fab" style="margin-right: 1em">
                </b-icon>
              </div>
              <div class="level-item" style="justify-content: start">
                <div>
                  <p><b>Suporte WhatsApp</b></p>
                  <p>
                    {{ telefone }}
                  </p>
                </div>
              </div>
            </div>
            <div class="level">
              <div class="level-left">
                <b-icon icon="envelope" pack="fas" style="margin-right: 1em">
                </b-icon>
              </div>
              <div class="level-item" style="justify-content: start">
                <div>
                  <p><b>Suporte E-mail</b></p>
                  <p>
                    suporte10@escala10.com.br
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <p class="title">Redes Sociais</p>
            <SocialMedias :list="socialMediasList"></SocialMedias>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-second">
      © 2021 Copyright Escala 10. Todos os direitos reservados.
    </div>
  </footer>
</template>

<script>
import SocialMedias from "@/components/generic/SocialMedias";
import SocialMediasMixin from "@/shared/SocialMedia";
import WhatsAppNotify from "@/_mixins/whatsapp";

export default {
  name: "Footer",
  mixins: [SocialMediasMixin, WhatsAppNotify],
  components: {
    SocialMedias,
  },
};
</script>
