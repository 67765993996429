export default { 
  atletasTable (state) {
    return state.atletas
  },
  atletasPage (state) {
    return state.page
  },
  atletasLimit (state) {
    return state.limit
  },
  atletasTotal (state) {
    return state.total
  },
  atletasParams (state) {
    return state.params
  },
  atletasFilter (state) {
    return state.filter
  },
  atletasOrder (state) {
    return state.order
  }
}