import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/categorias',
    name: 'Categorias',
    component: () => import('../../components/Categorias/Categorias'),
    meta: { p: "cms.categorias" },
    beforeEnter: AuthPermission
  },
]
