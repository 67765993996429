export default { 
  fin_params (state) {
    return state.parametros
  },
  
  fin_view (state) {
    return state.view
  },

  getFinDados (state) {
    return state.dados
  },

  finLoading (state) {
    return state.loading
  }
}