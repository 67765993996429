import AuthPermission from "@/shared/Authetication";

export default [
  {
    path: "/",
    name: "Influencers",
    component: () => import("../pages/Influencers.vue"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/influencers/:id",
    name: "Influencers",
    component: () => import("../pages/Influencers.vue"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/black-friday/:id",
    name: "Influencers Black Friday",
    component: () => import("../pages/Influencers.vue"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/assinatura",
    name: "RenewSubscription",
    component: () => import("../pages/RenewSubscription.vue"),
    meta: { maintenance: maintenance },
  },
];
