<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="label || name"
    :rules="rules"
  >
    <b-field
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="hideMessages ? '' : errors[0] ? errors : message"
      :label="label"
    >
      <b-select
        :id="id"
        :key="name"
        v-model="innerValue"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        expanded
      >
        <slot />
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Select',
  components: {
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Selecione'
    },
    vid: {
      type: String,
      default: undefined
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: null,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    hideMessages: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    innerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue, oldValue);
      }
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.innerValue = newValue;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
