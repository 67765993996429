import Access from './access/index'
import DashBoard from './dashboard/index'
import Influencers from './influencers/index'
import Ligas from './ligas/index'
import MiniGames from './minigames/index'
import Notification from './notification/index'
import Payout from './payout/index'
import Plans from './plans/index'
import Users from './users/index'
import Categorias from './categorias/index'

import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms',
    name: 'CMS',
    redirect: '/cms/ligas',
    component: () => import('../pages/CMS'),
    meta: { requiresAuth: true, maintenance: maintenance },
    beforeEnter: AuthPermission,
    children: [
      ...Access,
      ...DashBoard,
      ...Influencers,
      ...Ligas,
      ...MiniGames,
      ...Notification,
      ...Payout,
      ...Plans,
      ...Users,
      ...Categorias,
    ]
  }
]
