export const userInfo = (state) => state.userInfo;

export const userPermission = (state) => state.userPermission;

export const activePermission = (state) => state.activePermission;

export const renderMenu = (state) => state.renderMenu;

export const userValid = (state) => state.userValid;

export const userId = (state) => state.userInfo.id;

export const loggedIn = (state) => state.loggedIn;

export const loginAccess = (state) => state.loginAccess;

export const loginExpired = (state) => state.loginExpired;

export const existPayment = (state) => state.existPayment;

export const token = (state) => state.token;

export const atletas = (state) => state.atletas;

export const configAtletas = (state) => state.configAtletas;

export const statusAtletas = (state) => state.statusAtletas;

export const scouts = (state) => state.scouts;

export const posicoes = (state) => state.posicoes;

export const mercado = (state) => {
  let mercado = state.mercado;
  if ("mercado_aberto" in mercado) {
    mercado.mercado_aberto = mercado.mercado_aberto.data[0];
  }

  return state.mercado;
};

export const isAdmin = (state) => state.isAdmin;

export const onlyLiga = (state) => state.onlyLiga;

export const wallet = (state) => state.wallet;

export const leagues = (state) => state.leagues;

export const openSideBar = (state) => state.openSideBar;
