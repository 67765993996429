 /* eslint-disable */ 
import Vue from 'vue'

export default { 

   async getAllGames ({commit}) {
      let { data } = await Vue.http.get(url + '/games')
      return data.data
   },

   async getGamesByStatus({ commit }, { status, id_game }) {
      let { data } = await Vue.http.post(url + "/games/status", {
        status,
        id_game,
      });
      return data.data;
   },

   async getMyGames ({commit}, {userId, params}) {
      let { data } = await Vue.http.post(url + `/games/my/${userId}`, {params})
      return data.data
   },

   async getGameById ({commit}, {gameId}) {
      let { data } = await Vue.http.get(url + '/games/' + gameId)
      return data.data
   },

   async getGameRoomById ({commit}, {gameId, salaId}) {
      let { data } = await Vue.http.get(url + '/games/' + gameId + '/salas/' + salaId)
      return data.data
   },

   async getGameLevel2({ commit }, { id_game }) {
      let { data } = await Vue.http.get(url + "/games/" + id_game + "/level2");
      return data.data;
   },
  
   async delGameSubSalas({ commit }, { subSalas }) {
      let { data } = await Vue.http.post(url + "/games/salas/delsubsalas", {
        subSalas,
      });
      return data.data;
   },
 
  
   async getTypesGame({ commit }) {
      let { data } = await Vue.http.get(url + "/games/tipos");
      return data.data;
   },

   async getTypesGameSubSala({ commit }) {
      let { data } = await Vue.http.get(url + "/games/tipos/subsala");
      return data.data;
   },
   
   async getTypesGameRank({ commit }) {
      let { data } = await Vue.http.get(url + "/games/tipos/rank");
      return data.data;
   },
   
   async addNewGame ({ commit }, { userId, form }) {
      let { data } = await Vue.http.post(url + '/usuarios/' + userId + '/games', { game: form })
      return data.data
   },

   async deleteGame ({ commit }, { userId, gameId }) {
      let { data } = await Vue.http.post(url + '/usuarios/' + userId + '/games/delete', { gameId })
      return data.data
   },

   async flagGame ({ commit }, { userId, gameId }) {
      let { data } = await Vue.http.post(url + '/usuarios/' + userId + '/games/flag', { gameId })
      return data.data
   },

   async sortGames({ commit }, { userId, games }) {
      let { data } = await Vue.http.post(
        url + "/usuarios/" + userId + "/games/sort",
        { games }
      );
      return data.data;
    },
  
   async destaqueGame({ commit }, { userId, id_game }) {
      let { data } = await Vue.http.post(
         url + "/usuarios/" + userId + "/games/destaque",
         { id_game: id_game }
      );
      return data.data;
   },

   async destaqueGameInfluencer({ commit }, { userId, id_game }) {
      let { data } = await Vue.http.post(
         url + "/usuarios/" + userId + "/games/destaque-influencer",
         { id_game: id_game }
      );
      return data.data;
   },
  
   async getGameSubSalas({ commit }, { gameId, salaId }) {
      let { data } = await Vue.http.get(
         url + "/games/" + gameId + "/salas/" + salaId + "/info"
      );
      return data.data;
   },

   async getGameFiltrosApuracao({ commit }, { gameId, salaId }) {
      let { data } = await Vue.http.get(
        url + "/games/" + gameId + "/salas/" + salaId + "/filtros"
      );
      return data.data;
    },

    async getGameTeamDetails({ dispatch, commit }, { gameId, salaId, timeId, rodada, isParcial}) {
      let parciais = isParcial ? '1' : '0'
      let { data } = await Vue.http.get(
         url + "/games/" + gameId + "/salas/" + salaId + "/times/" + timeId + "/rodadas/" + rodada + "?parciais=" + parciais
      );
      return data.data;
    },
}