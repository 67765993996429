/* eslint-disable */

import Vue from "vue";

export default {

  async getCategorias({ commit }) {
    let { data } = await Vue.http.get(url + "/categorias");
    return data;
  },

  async getCategoriasLigas({ commit }, id) {
    let { data } = await Vue.http.get(url + "/categorias/" + id + "/ligas");
    return data;
  },

  async getCategoriasGames({ commit }, id) {
    let { data } = await Vue.http.get(url + "/categorias/" + id + "/games");
    return data;
  },

  async deleteCategoria({ commit }, id) {
    let { data } = await Vue.http.delete(url + "/categorias/" + id);
    return data;
  },

  async createCategoria({ commit }, categoria) {
    let res = await Vue.http.post(url + "/categorias", { categoria });
    return res;
  },

  async sortCategoria({ commit }, { userId, categorias }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/categorias/sort",
      { categorias }
    );
    return data.data;
  },

  async sortCategoriaLiga({ commit }, { userId, categoriaId, ligas }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/categorias/" + categoriaId + "/ligas/sort",
      { ligas }
    );
    return data.data;
  },
};
