import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/plans',
    name: 'Planos',
    component: () => import('../../components/Plans/Plans'),
    meta: { p: "cms.plans" },
    beforeEnter: AuthPermission
  },
]
