import AuthPermission from "@/shared/Authetication";

export default [
  {
    path: "/cms/ligas",
    name: "Ligas CMS",
    component: () => import("../../components/Ligas/Painel"),
    meta: { p: "cms.ligas" },
    beforeEnter: AuthPermission,
  },
  {
    path: "/cms/ligas/adicionar",
    name: "AdicionarLigas",
    component: () => import("../../components/Ligas/Adicionar"),
    meta: { p: "cms.ligas" },
    beforeEnter: AuthPermission,
  },
];
