/* eslint-disable */

export default {
  async getTypesTrade({ commit }, { userId, walletId, type }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/wallet/" + walletId + "/trades/types",
      { type }
    );
    await commit("SET_TYPES_TRADE", data.data);
    return data.data;
  },
  async addMovTrade({ commit }, { userId, walletId, trade }) {
    let { data } = await Vue.http.post(
      url + "/usuarios/" + userId + "/wallet/" + walletId + "/trades/addmov",
      { trade }
    );
    return data;
  },
};
