import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/minigames',
    name: 'Mini Games',
    component: () => import('../../components/MiniGames/Painel'),
    meta: { p: "cms.minigames" },
    beforeEnter: AuthPermission
  },
]
