import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/notification',
    name: 'Notificao',
    component: () => import('../../components/Notification/Notification'),
    meta: { p: "cms.notification" },
    beforeEnter: AuthPermission
  }
]
