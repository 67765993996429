export default { 
  async setAtletas({ commit }, data) {
    await commit ('SET_ATLETAS', data)
  },

  async setAtletasPage({ commit }, data) {
    await commit ('SET_PAGE', data)
  },

  async setAtletasLimit({ commit }, data) {
    await commit ('SET_LIMIT', data)
  },

  async setAtletasTotal({ commit }, data) {
    await commit ('SET_TOTAL', data)
  },

  async setAtletasParams({ commit }, data) {
    await commit ('SET_PARAMS', data)
  },

  async setAtletasFilter({ commit }, data) {
    await commit("SET_FILTER", data)
  },

  async setAtletasFilterKey({ commit }, data) {
    await commit("SET_FILTER_KEY", data)
  },

  async setAtletasOrder({ commit }, data) {
    await commit("SET_ORDER", data)
  }
}