<template>
  <b-navbar wrapper-class="container" fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          class="ajust-brand"
          src="../../assets/imagens/logotipo_green_10.png"
          alt="Escala 10"
          style="width: 120px"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="div">
        <SocialMedias :list="socialMediasList"></SocialMedias>
      </b-navbar-item>
      <b-navbar-item href="/ligas">
        Ligas
      </b-navbar-item>
      <b-navbar-item @click="sendMessageToZap">
        Suporte
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            tag="router-link"
            to="/login?action=cad"
            type="is-secondary"
          >
            Cadastrar
          </b-button>
          <b-button
            tag="router-link"
            to="/login?action=login"
            type="is-primary"
          >
            Entrar
          </b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import SocialMedias from "@/components/generic/SocialMedias";
import SocialMediasMixin from "@/shared/SocialMedia";
import WhatsAppNotify from "@/_mixins/whatsapp";

export default {
  name: "NavbarDefault",
  mixins: [SocialMediasMixin, WhatsAppNotify],
  components: {
    SocialMedias,
  },
};
</script>

<style></style>
