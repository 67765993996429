import Vue from "vue";
import cookies from "vue-cookies";
import Router from "vue-router";
import CMS from "../apps/CMS/routers/index";
import store from "../store/index";
import Ligas from "@/apps/Ligas/routes/index";
import AppPanelRoutes from "@/apps/Panel/routes/index";
import Public from "@/apps/Public/routes/index";

Vue.use(Router);

const routes = [
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../pages/NotFound"),
  },
  {
    path: "/403",
    name: "PageForbidden",
    component: () => import("../pages/Forbidden"),
  },
  {
    path: "/manutencao",
    name: "UnderMaintenance",
    component: () => import("../pages/UnderMaintenance"),
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../pages/Home/Home"),
  //   meta: { maintenance: maintenance },
  // },
  {
    path: "/promotion",
    name: "Promotion",
    component: () => import("../pages/Promotion"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/influencer/:id",
    redirect: "/influencers/:id",
  },
  {
    path: "/renewPage/influencer/:id",
    name: "RenewPage",
    component: () => import("../pages/RenewPage"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../apps/Auth/pages/Login"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/login-admin-escala10",
    name: "Login Admin",
    component: () => import("../pages/Login"),
    meta: { maintenance: false },
  },
  {
    path: "/support",
    name: "Suporte",
    component: () => import("../pages/Support"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/termos-de-uso",
    name: "Policies",
    component: () => import("../pages/Policies/Policies"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/renewSignature/:auserid",
    name: "RenewSignature",
    component: () => import("../pages/RenewSignature"),
    meta: { maintenance: maintenance },
  },
  {
    path: "/signature/:auserid",
    name: "Signature",
    component: () => import("../pages/Signature"),
    meta: { maintenance: maintenance },
  },
  ...AppPanelRoutes,
  ...CMS,
  ...Ligas,
  ...Public,
];

const router = new Router({
  mode: "history",
  routes,
});

async function underMaintenance(to) {
  let repair =
    to.matched.some((record) => record.meta.maintenance) == true &&
    !store.state.userInfo.isAdmin;
  return repair;
}

async function validAuth(to) {
  let auth = true;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      localStorage.loggedIn &&
      cookies.get("_nekot") &&
      typeof localStorage.userId != undefined
    ) {
      auth = localStorage.loggedIn;
      await store.dispatch("setLogin", localStorage.userId);
    } else {
      auth = store.state.loggedIn;
    }

    if (
      cookies.get("_nekot") == "null" ||
      cookies.get("_nekot") == "undefined" ||
      typeof cookies.get("_nekot") == "undefined"
    ) {
      auth = false;
    }
  }

  return auth;
}

async function validAccess() {
  let access = store.state.loginAccess;
  let expired = store.state.loginExpired;
  let payment = store.state.existPayment;
  let needRenewSignature = expired || !payment;

  let canAccess = !needRenewSignature && access;

  return { canAccess, needRenewSignature };
}

router.beforeEach(async (to, from, next) => {
  const isUnderMaintenance = await underMaintenance(to);
  if (!isUnderMaintenance) {
    let match = to.matched.some((record) => record.meta.requiresAuth);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const isAuthenticated = await validAuth(to);
      // if (!isAuthenticated) {
      const { canAccess, needRenewSignature } = await validAccess();
      if (!canAccess) {
        if (needRenewSignature)
          return next("/renewSignature/" + store.state.userId);
        return next("/signature/" + store.state.userId);
      } else if (!isAuthenticated) return next("/login");
      else return next();
      // } else return next();
    } else return next();
  } else return next("/manutencao");
});

export default router;
