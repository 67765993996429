export default { 
  SET_ATLETAS (state, val) {
    state.atletas = val
  },

  SET_PAGE (state, val) {
    state.page = val
  },

  SET_LIMIT (state, val) {
    state.limit = val
  },

  SET_TOTAL (state, val) {
    state.total = val
  },

  SET_PARAMS (state, val) {
    state.params = val
  },

  SET_FILTER (state, val) {
    state.filter = JSON.parse(JSON.stringify(val))
  },

  SET_FILTER_KEY: (state, { key, val }) => {
    let newParams = JSON.parse(JSON.stringify(state.filter));
    newParams[key] = val;
    state.filter = JSON.parse(JSON.stringify(newParams));
  },

  SET_ORDER (state, val) {
    state.order = val
  }
}