export default { 
  getterClubes(state) {
    return state.clubes
  },
  
  getterClubesId(state) {
    let aux = {}
    for (let el of state.clubes) {
      if (!aux[el.id]) aux[el.id] = {}
      aux[el.id] = el
    }
    return aux
  }
}