export default { 
  SET_PARAMS (state, val) {
    state.parametros = val
  },

  TROGGLE_VIEW (state, view) {
    state.view[view] = !state.view[view] 
  },

  TROGGLE_VIEW_FIX (state, { conq, ced }) {
    state.view.conquistado = conq
    state.view.cedido = ced 
  },

  SET_DADOS (state, val) {
    state.dados = val
  },

  SET_LOADING (state, val) {
    state.loading = val
  }
}