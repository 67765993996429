import { render, staticRenderFns } from "./BasePublicTemplate.vue?vue&type=template&id=4e4252eb&scoped=true&"
import script from "./BasePublicTemplate.vue?vue&type=script&lang=js&"
export * from "./BasePublicTemplate.vue?vue&type=script&lang=js&"
import style0 from "./BasePublicTemplate.vue?vue&type=style&index=0&id=4e4252eb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e4252eb",
  null
  
)

export default component.exports