import Vue from "vue";

// Sentry
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn:
      "https://2c79866cdbec4153b54de11bbd829d9c@o497154.ingest.sentry.io/5572838",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
