export default {
  data() {
    return {
      socialMediasList: [
        {
          content: "Telegram",
          link: "https://t.me/escala10",
          pack: "fab",
          icon: "telegram-plane",
        },
        {
          content: "WhatsApp",
          link: "https://api.whatsapp.com/send?phone=5521974399122",
          pack: "fab",
          icon: "whatsapp",
        },
        {
          content: "Facebook",
          link: "https://www.facebook.com/escala10.br/",
          pack: "fab",
          icon: "facebook",
        },
        {
          content: "Instagram",
          link: "https://www.instagram.com/escala10.br/",
          pack: "fab",
          icon: "instagram",
        },
      ],
    };
  },
};
