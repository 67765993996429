export default { 
  atletas: [],
  page: 1,
  limit: 25, 
  total: 0,
  params: {},
  filter: {},
  order: {
    field: 'preco', 
    type: 'desc'
  },
}