/* eslint-disable */

import Vue from "vue";

export default {
  async getContactsType({ commit }) {
    let { data } = await Vue.http.get(url + "/influenciadores/contactstype");
    return data;
  },

  async getInfluencers({ commit }) {
    let { data } = await Vue.http.get(url + "/influenciadores");
    return data;
  },

  async getInfluencersSales({ commit }, id) {
    let { data } = await Vue.http.get(url + `/influenciadores/sales/${id}`);
    return data.data;
  },

  async getInfluencerById({ commit }, id) {
    let { data } = await Vue.http.get(url + "/influenciadores/" + id);
    return data.data;
  },

  async getInfluencerBySlug({ commit }, slug) {
    let { data } = await Vue.http.get(url + "/influenciadores/slug/" + slug);
    if (data.status == "success") {
      await commit("SET_INFLUENCER", data.data);
    }
    return data;
  },

  async deleteInfluencerById({ commit }, id) {
    let { data } = await Vue.http.delete(url + "/influenciadores/" + id);
    return data;
  },

  async createInfluencer({ commit }, influencer) {
    let res = await Vue.http.post(url + "/influenciadores", { influencer });
    return res;
  },

  async getInfluencerInLigas({ commit }) {
    let { data } = await Vue.http.get(url + `/influenciadores/inligas`);
    return data.data;
  },

  async getInfluencerLigas({ commit }, influencerid) {
    let { data } = await Vue.http.get(
      url + `/influenciadores/${influencerid}/ligas`
    );
    return data.data;
  },

  async getInfluencerLigasById({ commit }, { influencerid, ligaId }) {
    let { data } = await Vue.http.get(
      url + `/influenciadores/${influencerid}/ligas/${ligaId}`
    );
    return data.data;
  },

  async getInfluencerTimesInscritos({ commit }, influencerid) {
    let { data } = await Vue.http.get(
      url + `/influenciadores/${influencerid}/times-inscritos`
    );
    return data.data;
  },

  async getInfluencerSubSalas({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/influenciadores/ligas/" + ligaId + "/salas/" + salaId + "/infos"
    );
    return data.data;
  },

  async getInfluencerGameSubSalas({ commit }, { gameId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/influenciadores/games/" + gameId + "/salas/" + salaId + "/infos"
    );
    return data.data;
  },

  async getInfluencerFiltrosApuracao({ commit }, { ligaId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/influenciadores/ligas/" + ligaId + "/salas/" + salaId + "/filtros"
    );
    return data.data;
  },

  async getInfluencerGameFiltrosApuracao({ commit }, { gameId, salaId }) {
    let { data } = await Vue.http.get(
      url + "/influenciadores/games/" + gameId + "/salas/" + salaId + "/filtros"
    );
    return data.data;
  },

  async getInfluencerLigasTimesSalas(
    { commit },
    { ligaId, salaId, filters, page, parcial, snv, orderBy }
  ) {
    let query =
      url + "/influenciadores/ligas/" + ligaId + "/salas/" + salaId + "/times";
    query += "?page=" + page;
    if (typeof filters !== "undefined" && filters !== null) {
      if ("field" in filters && "value" in filters && filters.value != "") {
        query += `&${filters.field}=${filters.value}`;
      }
    }
    if (parcial && snv) {
      query += "&parcial=1&snv=" + snv;
    }
    if (orderBy) {
      query += "&sort=" + orderBy;
    }

    let { data } = await Vue.http.get(query);
    return data.data;
  },

  async getInfluencerGamesTimesSalas(
    { commit },
    { gameId, salaId, filters, page, parcial, snv, orderBy }
  ) {
    let query =
      url + "/influenciadores/games/" + gameId + "/salas/" + salaId + "/times";
    query += "?page=" + page;
    if (typeof filters !== "undefined" && filters !== null) {
      if ("field" in filters && "value" in filters && filters.value != "") {
        query += `&${filters.field}=${filters.value}`;
      }
    }
    if (parcial && snv) {
      query += "&parcial=1&snv=" + snv;
    }
    if (orderBy) {
      query += "&sort=" + orderBy;
    }

    let { data } = await Vue.http.get(query);
    return data.data;
  },
};
