import store from "../store/";

export default {
  checkPermission(p) {
    try {
      const permissions = store.state.userPermission;
      if (p == "" || permissions.includes(p)) {
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    return false;
  },

  checkStatus(p) {
    try {
      const admin = store.state.isAdmin;
      const activePermissions = store.state.activePermission;
      if (admin || p == "" || activePermissions.includes(p)) {
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    return false;
  },

  checkLimitAccess() {
    return store.state.userInfo.limitAccess;
  },
};
