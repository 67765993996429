<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="label || name"
    :rules="rules"
    tag="div"
  >
    <b-field
      :type="{ 'is-danger': errors[0], 'is-success': valid && showValid }"
      :message="hideMessages ? '' : errors[0] ? errors : message"
      :label="label"
    >
      <template slot="label">
        <slot name="label"></slot>
      </template>

      <b-input
        :id="id"
        :key="name"
        v-model="innerValue"
        :name="name"
        :class="{ 'hide-icons': hideIcons, 'hide-arrows': hideArrows }"
        :type="type"
        :step="type === 'number' ? step : null"
        :placeholder="placeholder"
        :disabled="disabled"
        :password-reveal="type === 'password' ? passwordReveal : false"
        :icon="icon"
        :min="type === 'number' ? min : null"
        :max="type === 'number' ? max : null"
        :icon-right="iconRight"
        :readonly="readonly"
        autocomplete="off"
        @keyup.native.enter="onKeyupEnter"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "Input",
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    hideIcons: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: undefined,
    },
    value: {
      type: null,
      default: null,
    },
    step: {
      type: String,
      default: "1",
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    passwordReveal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    innerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
    value(newValue) {
      this.innerValue = newValue;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    onKeyupEnter() {
      this.$emit("onkeyupenter");
    },
    onKeyupInput() {
      if (
        this.innerValue !== this.value &&
        this.value !== null &&
        this.value !== ""
      ) {
        this.innerValue = this.value;
      }
    },
  },
};
</script>

<style lang="scss">
.field {
  div.hide-icons {
    .icon.is-right {
      display: none;
    }
  }

  div.hide-arrows {
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
      text-align: center;
    }
  }

  p.help {
    text-align: left !important;
  }
}
</style>
