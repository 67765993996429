 /* eslint-disable */ 
export default { 
  async getLigasTimesCartola ({ commit }, uid) {
    let { data } = await Vue.http.get(url + '/ligas/times/' + uid)
    await commit('SET_LIGAS_TIMES', data.data)
    return data.data
  },

  async addTimeInRoom ({ commit }, { ligaId, salaId, timeId, reservation, userId, saldo_bonus }) {
    let { data } = await Vue.http.post(url + '/ligas/' + ligaId + '/salas/' + salaId + '/times', {
      saldo_bonus,
      time: {
        id_cartola: timeId,
        id_pssa: userId,
      },
      reservation
    })
    return data.data    
  },

  async getLigasTimesSalas ({ commit }, { ligaId, salaId, filters, page, parcial, snv, orderBy }) {
    let query = url + '/ligas/' + ligaId + '/salas/' + salaId + '/times'
    query += '?page=' +  page
    if (typeof filters !== 'undefined' && filters !== null) {
      if ('field' in filters && 'value' in filters && filters.value != '') {
        query += `&${filters.field}=${filters.value}` 
      }
    }
    if (parcial && snv) {
      query += '&parcial=1&snv=' +  snv
    }
    if (orderBy) {
      query += '&sort=' + orderBy
    }

    let { data } = await Vue.http.get(query)
    return data.data
  },

  async getGamesTimes ({ commit }, {userId, gameId, salaId}) {
    let { data } = await Vue.http.post(url + '/games/times/' + userId, {gameId, salaId})
    await commit('SET_GAMES_TIMES', data.data)
    return data.data
  },

  async getGameCoach ({commit}, coach) {
    let { data } = await Vue.http.post(url + '/games/times/coach/get', { coach })
    return data.data
  },

  async setGameCoach ({commit}, {userId, coach}) {
    let { data } = await Vue.http.post(url + '/games/times/coach/set', { userId, coach })
    return data.data
  },

  // async addTimeInGame ({ commit }, {team}) {
  //   let { data } = await Vue.http.post(url + '/games/times/add', {team})
  //   return data.data
  // },
  async addTimeInGame ({ commit }, { gameId, salaId, time, reservation, userId, saldo_bonus }) {
    let { data } = await Vue.http.post(url + '/games/' + gameId + '/salas/' + salaId + '/times', {
      userId,
      time,
      reservation,
      saldo_bonus
    })
    return data.data    
  },

  async addAtletaInGame ({ commit }, {team, saldo_bonus}) {
    
    let gameId = team.id_game ? team.id_game : ''
    let salaId = team.id_sala ? team.id_sala : ''
    let userId = team.id_pssa ? team.id_pssa : ''
    let time = team.nome ? team.nome : ''

    let { data } = await Vue.http.post(url + '/games/'+gameId+'/salas/'+salaId+'/times/addAtletas', {
      userId,
      time,
      team,
      saldo_bonus
    })
    return data.data
  },

  async getGamesTimesSalas ({ commit }, { gameId, salaId, filters, page, parcial, snv, orderBy }) {
    let query = url + '/games/' + gameId + '/salas/' + salaId + '/times'
    query += '?page=' +  page
    if (typeof filters !== 'undefined' && filters !== null) {
      if ('field' in filters && 'value' in filters && filters.value != '') {
        query += `&${filters.field}=${filters.value}` 
      }
    }
    if (parcial && snv) {
      query += '&parcial=1&snv=' +  snv
    }
    if (orderBy) {
      query += '&sort=' + orderBy
    }

    let { data } = await Vue.http.get(query)
    return data.data
  },

  async disableTeam ({ commit }, { userId, teamId }) {
    let { data } = await Vue.http.put(`${url}/usuarios/${userId}/cartola/meuTimes/${teamId}`)
    return data
  },

  async setDesempenho ({ commit }, desempenho) {
    commit ('SET_DESEMPENHO', desempenho)
  }
} 