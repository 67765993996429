const telefone = "5521974399122";

export default {
  data() {
    return {
      telefone: "+55 21 974399122",
    };
  },
  methods: {
    sendMessageToZap(obj) {
      let text = "";

      if (typeof obj !== "undefined") {
        text = encodeURI(obj.text);
      }

      // TODO analytics
      window.open(
        `https://api.whatsapp.com/send?phone=${telefone}&text=${text}`,
        "_blank"
      );
    },
  },
};
