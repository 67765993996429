<template>
  <section class="restrictAccess" v-if="!urlAccess">
    <div
      v-if="appName !== 'cms' && slug !== 'cms' && !permission && !isAdmin"
      class="restrict-box"
      @click="restrictAlert('blocked')"
    >
      <b-tooltip
        label="Acesso restrito!"
        :active="!permission"
        type="is-danger"
        animated
      >
        <div
          class="lock-content"
          :class="{ 'lock-content-disable': !viewContent }"
        >
          <slot></slot>
        </div>
        <div class="lock-box-blocked"></div>
        <div v-if="viewContent" class="lock-icon">
          <b-tag type="is-danger" rounded>
            <b-icon icon="lock" pack="fas" size="is-small"></b-icon>
          </b-tag>
        </div>
        <div v-if="!viewContent" class="lock-stamp is centered is-vcentered">
          <b-icon icon="lock" pack="fas"></b-icon>
        </div>
      </b-tooltip>
    </div>
    <div
      v-if="permission && !active"
      class="restrict-box"
      @click="restrictAlert('disabled')"
    >
      <b-tooltip
        label="Manutenção!"
        :active="!active"
        type="is-warning"
        animated
      >
        <div
          class="lock-content"
          :class="{ 'lock-content-disable': !viewContent }"
        >
          <slot></slot>
        </div>
        <div class="lock-box-disabled"></div>
        <div v-if="viewContent" class="lock-icon">
          <b-tag type="is-warning" rounded>
            <b-icon icon="wrench" pack="fas" size="is-small"></b-icon>
          </b-tag>
        </div>
        <div v-if="!viewContent" class="lock-stamp is centered is-vcentered">
          <b-icon icon="wrench" pack="fas"></b-icon>
        </div>
      </b-tooltip>
    </div>
    <div class="restrict-access-enabled" v-if="permission && active">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "restrictAccess",
  props: {
    slug: {
      type: String,
      default: () => "",
    },
    content: {
      type: Boolean,
      default: () => true,
    },
    appName: {
      type: String,
      default: () => "painel",
    },
    style: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isAdmin: false,
      permission: true,
      active: true,
      urlAccess: false,
      viewContent: false,
    };
  },
  computed: {
    ...mapGetters(["userId", "userInfo", "userPermission", "activePermission"]),
  },
  created() {
    this.urlAccess = this.$route.params.aslug;
    this.viewContent = this.urlAccess ? false : this.content;

    let slug = this.urlAccess ? this.$route.params.aslug : this.slug;

    if (slug.indexOf(",") !== -1) {
      slug = slug.split(",");
      slug.map((s) => {
        this.permission =
          this.$accessControl.checkPermission(s) && this.permission;
        this.active = this.$accessControl.checkStatus(s) && this.active;
      });
    } else {
      this.permission = this.$accessControl.checkPermission(slug);
      this.active = this.$accessControl.checkStatus(slug);
    }

    this.isAdmin = this.userInfo.isAdmin;

    if (this.isAdmin) {
      this.active = true;
    }

    if (!this.viewContent) {
      if (!this.permission || !this.active) {
        this.restrictAlert(!this.permission ? "blocked" : "disabled");
      }
    }
  },
  methods: {
    ...mapActions(["getAllAccess", "flagAccess"]),
    restrictAlert(status) {
      if (status == "blocked") {
        this.$buefy.dialog.confirm({
          title: "ACESSO RESTRITO !",
          message:
            "<p>Fala, Cartoleiro!</p><p>Infelizmente, seu plano não tem acesso à esta tela. Quer atualizar seu plano, ter acesso completo ao Escala10 e aumentar suas chances de mitar no Cartola FC 2020?</p>",
          confirmText: "Atualizar",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.$router.push({
              path: `/renewSignature/${this.userId}?action=upgrade`,
            });
          },
        });
      } else if (status == "disabled") {
        this.$buefy.dialog.alert({
          title: "ÁREA EM MANUTENÇÃO !",
          message:
            "<p>Fala, Cartoleiro!</p><p>Esta tela está temporariamente em manutenção e em breve vamos liberar o acesso.</p><p>Estamos sempre trabalhando para melhorar sua experiência no Escala10!</p>",
          type: "is-warning",
          hasIcon: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.restrictAccess {
  margin: none !important;
  padding: none !important;
  cursor: pointer;
}
.restrictAccess div {
  margin: none !important;
  padding: none !important;
}
.restrictAccess .restrict-box {
  position: relative;
}
.restrictAccess .lock-icon {
  position: absolute;
  top: 0px;
  left: 0px;
}
.restrictAccess .lock-stamp {
  position: absolute;
  font-size: 100px;
  top: calc(50% - 30px);
  left: calc(50% - 15px);
  /* top: 50%;
  left: 50%; */
  /* padding: 20px;
  border-radius: 100px;
  background-color: #ff3860; */
}
.restrictAccess .lock-box-blocked {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.1);
}
.restrictAccess .lock-box-disabled {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0.1);
}
.restrictAccess .lock-content {
  opacity: 0.9;
}
.restrictAccess .lock-content-disable {
  opacity: 0;
}
</style>
