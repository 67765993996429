export default {
  teamsdata: [],
  isPublic: false,
  ligasList: [],
  ligasFiltros: {},
  ligasParams: {
    modalidade: ['liga','game'],
    status: ["aberta", "em_andamento"],
    tipo: [],
    apuracao: [],
    capitao: [],
    valor: [0,1000]
  },
  enroll: {
    availableTeams: [],
    teamsEnrolled: [],
  },
  sala: {},
  reservedSalas: [],
  salasChosen: [],
  cart: [],
  cartStep: 1,
  openedCart: false,
  reducedCart: false
};
