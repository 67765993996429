import AuthPermission from '@/shared/Authetication'

export default [
  {
    path: '/cms/dashboard',
    name: 'DashBoard',
    component: () => import('../../components/DashBoard/DashBoard'),
    meta: { p: "cms.dashboard" },
    beforeEnter: AuthPermission
  },
]
