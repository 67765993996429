export const setResource = (state, http) => {
  state.http = http
}

export const SET_USER_INFO = (state, userInfo) => {
  state.userInfo = userInfo
}

export const SET_USER_PERMISSION = (state, userPermission) => {
  state.userPermission = userPermission.map((p) => {
    return p.slug;
  });
  
}

export const SET_ACTIVE_PERMISSION = (state, activePermission) => {
  state.activePermission = activePermission
}

export const SET_RENDER_MENU = (state, renderMenu) => {
  state.renderMenu = renderMenu
}

export const SET_USER_VALID = (state, userValid) => {
  state.userValid = userValid
}

export const SET_USER_ID = (state, id) => {
  state.userId = id
}

export const SET_LOGIN = (state, statusLogin) => {
  state.loggedIn = statusLogin
}

  export const SET_LOGIN_ACCESS = (state, statusAccess) => {
    state.loginAccess = statusAccess
  }

export const SET_LOGIN_EXPIRED = (state, statusExpired) => {
  state.loginExpired = statusExpired
}

export const SET_EXIST_PAYMENT = (state, statusPayment) => {
  state.existPayment = statusPayment
}

export const SET_TOKEN = (state, token) => {
  state.token = token
}

export const GET_ATLETAS = (state, atletas) => {
  state.atletas = atletas
}

export const SET_CONFIGATLETAS = (state, configAtletas) => {
  state.configAtletas = configAtletas
}

export const GET_STATUS_ATLETAS = (state, statusAtletas) => {
  state.statusAtletas = statusAtletas
}

export const GET_SCOUTS = (state, scouts) => {
  state.scouts = scouts
}

export const GET_POSICOES = (state, posicoes) => {
  state.posicoes = posicoes
}

export const GET_MERCADO = (state, mercado) => {
  state.mercado = mercado
}

export const SET_ADMIN = (state, admin) => {
  state.isAdmin = admin
}

export const SET_ONLY_LIGA = (state, onlyLiga) => {
  state.onlyLiga = onlyLiga
}

export const GET_WALLET = (state, wallet) => {
  state.wallet = wallet
}

export const GET_LEAGUES = (state, leagues) => {
  state.leagues.data = leagues
}

export const SET_STATUS_SIDEBAR = (state, active) => {
  state.openSideBar = active
}

export const GET_LEAGUES_INFO = (state, info) => {
  state.leagues.data.map(el => {
    el.user_teams_registered = info[el.id_liga]
  })
}

export const writeStatus = (state, { success, message, processing }) => {
  state.modal = {
    success: success,
    message: message,
    processing: processing
  }
}
