<template>
  <section class="bpt-container" :style="classList" @click="$emit('click')">
    <b-loading
      v-if="isLoading"
      active
      is-full-page
      :can-cancel="false"
    ></b-loading>
    <slot v-if="!isLoading" name="header" />
    <div class="base-public-template">
      <div
        class="container is-fluid"
        :banner="hasBanner && !pageIsCaixa"
        :class="{ 'ajust-limited-access': !isInfluencerPage && limitedAccess }"
      >
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "basePublicLayout",
  props: {
    paddingless: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
    },
    hasBanner: {
      type: Boolean,
      default: false,
    },
    isInfluencerPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      let list = {};
      if (this.paddingless) list.padding = `0px`;
      if (this.backgroundColor) list.backgroundColor = this.backgroundColor;
      if (this.backgroundImage)
        list.backgroundImage = `url(${this.backgroundImage})`;
      return list;
    },
    limitedAccess() {
      return this.$accessControl.checkLimitAccess();
    },
    pageIsCaixa() {
      return ["Caixa Influenciadores", "Caixa"].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.bpt-container {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #1f213179;
}
.base-public-template {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  padding: 1.25em 0px;

  .container {
    margin-top: 30px;

    &[banner] {
      margin-top: 30px !important;
    }
  }
  @media (max-width: 768px) {
    .container {
      margin-top: 50px !important;

      &[banner] {
        margin-top: 30px !important;
      }
    }
  }

  .ajust-limited-access {
    margin-top: 100px !important;
    padding-bottom: 50px !important;

    @media (max-width: 768px) {
      & {
        margin-top: 140px !important;
        padding-bottom: 70px !important;
      }
    }
  }
}
@media (max-width: 768px) {
  .base-public-template {
    width: 100% !important;
    padding: 1.25em 0px;

    .container.is-fluid {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
}
</style>
