 /* eslint-disable */ 
export default { 
   async getRodadas ({ commit }, mes) {
      let { data } = await Vue.http.post(url + '/mercado/rodadas', {mes})
      return data.data
   },

   async getFechamento ({ commit }, rodada) {
      let { data } = await Vue.http.post( url + '/mercado/fechamento', {rodada})
      return data.data
   },

   async getJogos ({ commit }, rodada) {
      let { data } = await Vue.http.post( url + '/jogos/partidas/' + rodada)
      return data.data
   }
}