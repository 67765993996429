import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(Loading, {
  color: "#00fffd",
  heigth: 100,
  width: 100,
  loader: "dots",
  backgroundColor: "#000000",
});
