// variaveis globais para metodos / templates

__posicoes = [
  { sigla: 'gol', nome: 'Goleiros'}, 
  { sigla: 'ld', nome: 'Lateral Direito'}, 
  { sigla: 'le', nome: 'Lateral Esquerdo'}, 
  { sigla: 'ld,le', nome: 'Todos os Laterais'}, 
  { sigla: 'zag', nome: 'Zagueiros'}, 
  { sigla: 'mei', nome: 'Meia'}, 
  { sigla: 'vol', nome: 'Volante'}, 
  { sigla: 'mei,vol', nome: 'Meias e Volantes'}, 
  { sigla: 'ata', nome: 'Atacantes'}
];

__scouts = [
  {sigla: 'A',   nome: 'Assistências'}, 
  {sigla: 'DD',  nome: 'Defesas Difíceis'}, 
  {sigla: 'DP',  nome: 'Defesas de Pênalti'}, 
  {sigla: 'FD',  nome: 'Finalizações Defendidas'}, 
  {sigla: 'FF',  nome: 'Finalizações Foras'}, 
  {sigla: 'FS',  nome: 'Faltas Sofridas'}, 
  {sigla: 'FT',  nome: 'Finalizações na Trave'}, 
  {sigla: 'G',   nome: 'Gols'}, 
  {sigla: 'DS',  nome: 'Desarmes'}, 
  {sigla: 'SG',  nome: 'Jogos sem Sofrer Gols'},
  {sigla: 'CA',  nome: 'Cartões Amarelo'}, 
  {sigla: 'CV',  nome: 'Cartôes Vermelho'}, 
  {sigla: 'FC',  nome: 'Faltas Cometidas'}, 
  {sigla: 'GC',  nome: 'Gols Contra'}, 
  {sigla: 'GS',  nome: 'Gols Sofrido'}, 
  {sigla: 'I',   nome: 'Impedimentos'}, 
  {sigla: 'PI',  nome: 'Passes Incompletos'}, 
  {sigla: 'PP',  nome: 'Pênaltis Perdido'}
];

__scouts_kpi = [
  {tipo: 'kpi', sigla: 'DES',   nome: 'Desarmes'}, 
  {tipo: 'kpi', sigla: 'FINTT',   nome: 'Finalização Total'}, 
  {tipo: 'kpi', sigla: 'DDFIN',   nome: 'Defesa Difícil por Finalização'}, 
  {tipo: 'kpi', sigla: 'GLO',   nome: 'Gols de Linha Ofensiva'}, 
  {tipo: 'kpi', sigla: 'GPP',   nome: '% gols Por Posicao'}, 
  {tipo: 'scout', sigla: 'A',   nome: 'Assistências'}, 
  {tipo: 'scout', sigla: 'DD',  nome: 'Defesas Difíceis'}, 
  {tipo: 'scout', sigla: 'DP',  nome: 'Defesas de Pênalti'}, 
  {tipo: 'scout', sigla: 'FD',  nome: 'Finalizações Defendidas'}, 
  {tipo: 'scout', sigla: 'FF',  nome: 'Finalizações Foras'}, 
  {tipo: 'scout', sigla: 'FS',  nome: 'Faltas Sofridas'}, 
  {tipo: 'scout', sigla: 'FT',  nome: 'Finalizações na Trave'}, 
  {tipo: 'scout', sigla: 'G',   nome: 'Gols'}, 
  {tipo: 'scout', sigla: 'DS',  nome: 'Desarmes'}, 
  {tipo: 'scout', sigla: 'SG',  nome: 'Jogos sem Sofrer Gols'},
  {tipo: 'scout', sigla: 'CA',  nome: 'Cartões Amarelo'}, 
  {tipo: 'scout', sigla: 'CV',  nome: 'Cartôes Vermelho'}, 
  {tipo: 'scout', sigla: 'FC',  nome: 'Faltas Cometidas'}, 
  {tipo: 'scout', sigla: 'GC',  nome: 'Gols Contra'}, 
  {tipo: 'scout', sigla: 'GS',  nome: 'Gols Sofrido'}, 
  {tipo: 'scout', sigla: 'I',   nome: 'Impedimentos'}, 
  {tipo: 'scout', sigla: 'PI',  nome: 'Passes Incompletos'}, 
  {tipo: 'scout', sigla: 'PP',  nome: 'Pênaltis Perdido'}
];


__filters = ['3 jogos', '5 jogos', '1 turno', '2 turno', 'todos'];

__status = ['Provável', 'Nulo', 'Dúvida', 'Contundido', 'Suspenso'];
